import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CustomDataTableModule } from './../shared/custom-data-table/custom-data-table.module';
/* Angular Modules */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatDialogModule } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

/* Interceptors */
import { AuthInterceptor } from './services/interceptors/token.interceptor';
import { CacheInterceptor } from './services/interceptors/cache.interceptor';
import { HttpErrorInterceptor } from './services/interceptors/http-error.interceptor';
import { TradeCacheInterceptor } from '../trade-pro/advanced-trade/shared/services/trade-cache.interceptor';

/* 3rd Party Modules */
import player from 'lottie-web';
import { FooterModule } from 'footer';
import { HeaderModule } from 'header';
import { ToastrModule } from 'ngx-toastr';
import { IntercomModule } from 'ng-intercom-x';
import { NgSelectConfig, NgSelectModule } from '@ng-select/ng-select';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';

/* Components */
import { ShellComponent } from './components/shell/shell.component';
import { NoInternetComponent } from './components/no-internet/no-internet.component';
import { CookiePopupComponent } from './components/cookie-popup/cookie-popup.component';
import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { ServerManagementComponent } from './components/server-management/server-management.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ShellMobileComponent } from './components/shell-mobile/shell-mobile.component';
import { FourNotFourComponent } from './components/four-not-four/four-not-four.component';
import { FeeStructureComponent } from './components/fee-structure/fee-structure.component';
import { FormsModule } from '@angular/forms';
import { NgxEchartsModule } from 'ngx-echarts';
import { AdminRestrictedComponent } from './components/admin-restricted/admin-restricted.component';

/* Standalone */
import { LazyImgDirective } from './directives/lazy-img.directive';
import { PrecisionPipe } from '../standalone/pipes/precision.pipe';
import { StopApiInterceptor } from './services/interceptors/stop-api.interceptor';
import { CommonPaginationComponent } from '../standalone/components/common-pagination/common-pagination.component';
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, environment.LANGUAGE_URL, '.json?r=' + Math.random());
}

export function playerFactory() {
    return player;
}

export function getDatepickerConfig(): BsDatepickerConfig {
    return Object.assign(new BsDatepickerConfig(), {
        dateInputFormat: 'DD/MM/YYYY'
    });
}
@NgModule({
    declarations: [
        ShellComponent,
        LoaderComponent,
        ServerManagementComponent,
        NoInternetComponent,
        CookiePopupComponent,
        AuthLayoutComponent,
        ShellMobileComponent,
        FeeStructureComponent,
        AdminRestrictedComponent,
        // directives
        LazyImgDirective
    ],
    imports: [
        CommonModule,
        RouterModule,
        OverlayModule,
        MatDialogModule,
        HttpClientModule,
        FooterModule.forRoot(environment),
        HeaderModule.forRoot(environment),
        LottieModule.forRoot({ player: playerFactory }),
        LottieCacheModule.forRoot(),
        PopoverModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        ToastrModule.forRoot({
            timeOut: 1000,
            preventDuplicates: true,
            closeButton: true,
            progressBar: true
        }),
        IntercomModule.forRoot({
            appId: environment.INTERCOM_APP_ID, // from your Intercom config
            updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
        }),
        // TourMatMenuModule,
        FormsModule,
        NgSelectModule,
        CustomDataTableModule,
        NgxSkeletonLoaderModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        }),

        // Standalone
        PrecisionPipe,
        CommonPaginationComponent,
        FourNotFourComponent
    ],
    exports: [OverlayModule, FeeStructureComponent, NoInternetComponent, LoaderComponent],
    providers: [
        { provide: BsDatepickerConfig, useFactory: getDatepickerConfig },
        { provide: HTTP_INTERCEPTORS, useClass: StopApiInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TradeCacheInterceptor, multi: true }
    ]
})
export class CoreModule {
    constructor(private config: NgSelectConfig, private translate: TranslateService) {
        this.config.notFoundText = this.translate.instant('GLOBAL.NO_DATA_AVAILABLE');
    }
}
