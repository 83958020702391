import { isPlatformBrowser } from '@angular/common';
import { ErrorHandler, Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { NewReleaseModalComponent } from './app/standalone/components/new-release-modal/new-release-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ChunkErrorHandler implements ErrorHandler {
    ngbModal;
    constructor(private injector: Injector, @Inject(PLATFORM_ID) private platformId: Object) {}
    handleError(error: any): void {
        const chunkFailedMessage = /Loading chunk/;
        const unexpectedToken = /Unexpected token/;
        console.trace('chunk error', error);

        if (
            isPlatformBrowser(this.platformId) &&
            (chunkFailedMessage.test(error.message) || unexpectedToken.test(error.message))
        ) {
            this.ngbModal = this.injector.get(NgbModal);
            this.updateModal();
        }
    }

    clearDataAndReload() {
        window.location.reload();
        if (caches?.keys?.length > 0) caches.keys().then((e: any) => e.forEach(k => caches.delete(k)));
    }
    updateModal() {
        const modalRef = this.ngbModal.open(NewReleaseModalComponent, {
            size: 'md',
            centered: true,
            backdrop: 'static',
            modalDialogClass: 'updateAvailable'
        });
        modalRef.componentInstance.data = modalRef;
        modalRef.result.then(res => {
            this.clearDataAndReload();
        });
    }
}
