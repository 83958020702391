<ng-container *ngIf="data?.type === COMPLIANCE_TYPE.TASK_UBO">
    <div class="modal-header border-0">
        <h5 class="my-2 pt-2">{{ 'COMPLIANCE.UBO.TITLE' | translate }}</h5>
    </div>
    <div class="modal-body ixfi-pin-modal px-0">
        <p>
            {{ 'COMPLIANCE.UBO.TEXT' | translate }}
        </p>

        <div class="d-flex justify-content-center mx-auto gap-2 flex-sm-row flex-column">
            <button type="submit" (click)="taskUBO('confirm')" class="btn-default confirm-modal-btn btn-xl">
                {{ 'COMPLIANCE.UBO.CONFIRM' | translate }}
            </button>
            <button type="submit" (click)="taskUBO('decline')" class="btn btn-generate btn-xl">
                {{ 'COMPLIANCE.UBO.DECLINE' | translate }}
            </button>
        </div>
    </div>
</ng-container>

<ng-template #infoLink>
    <a href="https://cdn.ixfi.com/reward/pep_rules.pdf" rel="noopener" target="_blank">
        <div class="more-information">
            <div class="d-flex align-items-center gap-2">
                <div class="bill-img">
                    <img [src]="CDN + 'profile/bill.webp'" alt="bill" />
                </div>
                <p>{{ 'COMPLIANCE.PEP.MORE_INFO' | translate }}</p>
            </div>

            <em class="flaticon-right-arrow-1"></em>
        </div>
    </a>
</ng-template>

<ng-container *ngIf="data?.type === COMPLIANCE_TYPE.TASK_PEP_DONE">
    <div class="modal-body text-center px-0 pt-0">
        <div class="image-check">
            <img [src]="CDN + 'profile/tick-circle.webp'" alt="tick" />
        </div>
        <h5 class="black-text">{{ 'COMPLIANCE.COMPLETE.TITLE' | translate }}</h5>
        <p class="grey-text">{{ 'COMPLIANCE.COMPLETE.DESCRIPTION' | translate }}</p>

        <div class="form-area">
            <button type="submit" class="btn-default btn-xxl px-5 mb-2" (click)="done()">
                {{ 'COMPLIANCE.COMPLETE.DONE' | translate }}
            </button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="data?.type === COMPLIANCE_TYPE.TASK_CHECK_PEP && !showForm">
    <div class="modal-header border-0">
        <h5 class="my-2 pt-2">{{ 'COMPLIANCE.PEP.TITLE' | translate }}</h5>
    </div>
    <div class="modal-body ixfi-pin-modal px-0">
        <p>
            {{ 'COMPLIANCE.PEP.TEXT' | translate }}
        </p>
        <div class="compliance-content-section">
            <div class="accept-check">
                <div class="accept-section">
                    <label for="radio-card-1" class="radio-card">
                        <input type="radio" name="radio-btn" id="radio-card-1" (click)="pepValue = 'next'" />
                        <div class="card-content-wrapper">
                            <span class="check-icon"></span>
                        </div>

                        {{ 'COMPLIANCE.PEP.YES' | translate }} {{ 'COMPLIANCE.PEP.YES_TEXT' | translate }}
                    </label>
                </div>
                <div class="accept-section">
                    <label for="radio-card-2" class="radio-card">
                        <input type="radio" name="radio-btn" id="radio-card-2" (click)="pepValue = 'confirm'" />
                        <div class="card-content-wrapper">
                            <span class="check-icon"></span>
                        </div>
                        {{ 'COMPLIANCE.PEP.NO' | translate }}{{ 'COMPLIANCE.PEP.NO_TEXT' | translate }}
                    </label>
                </div>
            </div>

            <ng-template *ngTemplateOutlet="infoLink"></ng-template>
        </div>

        <div class="d-flex justify-content-center mx-auto gap-2 flex-sm-row flex-column">
            <button
                [ngClass]="{
                    'disable btn btn-generate ': pepValue !== 'confirm',
                    'btn-default': pepValue == 'confirm'
                }"
                [disabled]="pepValue !== 'confirm'"
                type="submit"
                class="confirm-modal-btn btn-xl"
                (click)="taskCheckPEP('confirm')">
                {{ 'COMPLIANCE.PEP.CONFIRM' | translate }}
            </button>
            <button
                (click)="taskCheckPEP('next')"
                [disabled]="pepValue !== 'next'"
                [ngClass]="{
                    'disable btn btn-generate': pepValue !== 'next',
                    'btn-default ': pepValue == 'next'
                }"
                class="confirm-modal-btn btn-xl"
                type="submit">
                {{ 'COMPLIANCE.PEP.NEXT' | translate }}
            </button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="showForm">
    <div class="modal-header border-0">
        <h5 class="my-2 pt-2">{{ 'COMPLIANCE.PEP_YES.TITLE' | translate }}</h5>
    </div>
    <div class="modal-body ixfi-pin-modal px-3">
        <p>
            {{ 'COMPLIANCE.PEP_YES.TEXT' | translate }}
        </p>
        <div class="compliance-content-section">
            <div class="accept-check">
                <div class="accept-section">
                    <label for="radio-card-1" class="radio-card">
                        <input type="radio" name="radio-btn-1" id="radio-card-1" checked />
                        <div class="card-content-wrapper">
                            <span class="check-icon"></span>
                        </div>

                        {{ 'COMPLIANCE.PEP_YES.YES' | translate }}
                        {{ 'COMPLIANCE.PEP_YES.YES_TEXT' | translate }}
                    </label>
                </div>
            </div>
            <form class="form-border" [formGroup]="complianceForm">
                <div class="politically-section" formGroupName="pep">
                    <div class="check-label">
                        <label class="d-flex align-items-center gap-2 mb-4">
                            <input type="checkbox" class="form-check-input" (change)="pepYesSelection($event, 'pep')" />
                            <span class="label-text">
                                {{ 'COMPLIANCE.PEP_YES.SELF.YES_TEXT' | translate }}
                            </span>
                        </label>
                    </div>
                    <div class="form-area mt-2" *ngIf="pepSelection">
                        <div class="form-group form-group-lg">
                            <div class="">
                                <input
                                    type="text"
                                    class="form-control"
                                    formControlName="fullname"
                                    placeholder="Full Name"
                                    id="fullname" />
                            </div>
                        </div>
                        <div class="form-group form-group-lg">
                            <div class="">
                                <input
                                    type="text"
                                    class="form-control"
                                    formControlName="position"
                                    placeholder="Position"
                                    id="position" />
                            </div>
                        </div>
                        <div class="form-group form-group-lg d-flex gap-2 country-select-box">
                            <div class="ng-select-coin w-100">
                                <div class="country-sheet d-flex d-md-none form-control" (click)="openCountryModal(i)">
                                    <div class="code-number">
                                        <span class="code"> Country </span>
                                        <em class="flaticon flaticon-down"></em>
                                    </div>
                                </div>

                                <ng-select
                                    (click)="unfreezeInput($event)"
                                    class="form-control d-none d-md-block"
                                    [items]="countryList"
                                    #phoneCodeSelect
                                    bindLabel="name"
                                    bindValue="name"
                                    [searchable]="false"
                                    [virtualScroll]="true"
                                    formControlName="country"
                                    [clearable]="false"
                                    [placeholder]="'Country'"
                                    (open)="focusInputField()"
                                    [notFoundText]="'GLOBAL.NO_DATA_AVAILABLE' | translate"
                                    [searchFn]="customSearch">
                                    <ng-template ng-label-tmp let-item="item" let-search="searchTerm">
                                        <div class="country-list">
                                            <div
                                                class="country-row d-flex align-items-center justify-content-between"
                                                *ngIf="item.id">
                                                <div class="country-img">
                                                    <img
                                                        [src]="CDN + 'country/' + item.id + '.png'"
                                                        [alt]="item.name" />
                                                </div>
                                                <div class="country-name">
                                                    <span class="coin_short_name"> {{ item.name }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>

                                    <ng-template ng-header-tmp let-item="item">
                                        <div class="d-flex align-items-center search-item">
                                            <input
                                                #phoneCodeSelectSearch
                                                type="text"
                                                appAutofocus
                                                class="form-control input-sm ng-select-search-box"
                                                placeholder="{{ 'LOGIN-PAGE.SEARCH' | translate }}"
                                                (input)="phoneCodeSelect.filter($event.target.value)" />
                                        </div>
                                    </ng-template>
                                    <ng-template
                                        ng-option-tmp
                                        let-item="item"
                                        let-index="index"
                                        let-search="searchTerm">
                                        <div class="country-list">
                                            <div class="country-row d-flex align-items-center" *ngIf="item.id">
                                                <div class="country-img">
                                                    <img
                                                        [src]="CDN + 'country/' + item.id + '.png'"
                                                        [alt]="item.name" />
                                                </div>
                                                <span class="coin_short_name"> {{ item.name }}</span>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="check-label">
                        <label class="d-flex align-items-center gap-2">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                (change)="pepYesSelection($event, 'nonPEP')" />
                            <span class="label-text"> {{ 'COMPLIANCE.PEP_YES.OTHER.CLOSE' | translate }} </span>
                        </label>
                    </div>
                </div>

                <div class="form-area d-flex flex-column gap-4 mt-2" formArrayName="notpep" *ngIf="nonPEPSelection">
                    <ng-container *ngFor="let skill of complianceForm.get('notpep').controls; let i = index">
                        <div class="form-add-more-section" [formGroupName]="i">
                            <div class="delete-btn" (click)="removeNotPEP(i)" *ngIf="i > 0">
                                <a class="d-flex"><img [src]="CDN + 'shared/trash.svg'" alt="trash" /></a>
                            </div>
                            <div class="form-group form-group-lg">
                                <div class="">
                                    <input
                                        type="text"
                                        formControlName="fullname"
                                        class="form-control"
                                        placeholder="Full Name"
                                        name="fullname"
                                        id="fullname" />
                                </div>
                            </div>
                            <div class="form-group form-group-lg">
                                <div class="">
                                    <input
                                        type="text"
                                        formControlName="position"
                                        class="form-control"
                                        placeholder="Position"
                                        name="position"
                                        id="position" />
                                </div>
                            </div>
                            <div class="form-group form-group-lg d-flex gap-2 country-select-box">
                                <div class="ng-select-coin w-100">
                                    <div
                                        class="country-sheet d-flex d-md-none form-control"
                                        (click)="openCountryModal(i)">
                                        <div class="code-number">
                                            <span class="code"> Country </span>
                                            <em class="flaticon flaticon-down"></em>
                                        </div>
                                    </div>

                                    <ng-select
                                        (click)="unfreezeInput($event)"
                                        class="form-control d-none d-md-block"
                                        [items]="countryList"
                                        #phoneCodeSelect
                                        bindLabel="name"
                                        bindValue="name"
                                        [searchable]="false"
                                        [virtualScroll]="true"
                                        formControlName="country"
                                        [clearable]="false"
                                        [placeholder]="'Country'"
                                        (open)="focusInputField()"
                                        [notFoundText]="'GLOBAL.NO_DATA_AVAILABLE' | translate"
                                        [searchFn]="customSearch">
                                        <ng-template ng-label-tmp let-item="item" let-search="searchTerm">
                                            <div class="country-list">
                                                <div
                                                    class="country-row d-flex align-items-center justify-content-between"
                                                    *ngIf="item.id">
                                                    <div class="country-img">
                                                        <img
                                                            [src]="CDN + 'country/' + item.id + '.png'"
                                                            [alt]="item.name" />
                                                    </div>
                                                    <div class="country-name">
                                                        <span class="coin_short_name"> {{ item.name }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>

                                        <ng-template ng-header-tmp let-item="item">
                                            <div class="d-flex align-items-center search-item">
                                                <input
                                                    #phoneCodeSelectSearch
                                                    type="text"
                                                    appAutofocus
                                                    class="form-control input-sm ng-select-search-box"
                                                    placeholder="{{ 'LOGIN-PAGE.SEARCH' | translate }}"
                                                    (input)="phoneCodeSelect.filter($event.target.value)" />
                                            </div>
                                        </ng-template>
                                        <ng-template
                                            ng-option-tmp
                                            let-item="item"
                                            let-index="index"
                                            let-search="searchTerm">
                                            <div class="country-list">
                                                <div class="country-row d-flex align-items-center" *ngIf="item.id">
                                                    <div class="country-img">
                                                        <img
                                                            [src]="CDN + 'country/' + item.id + '.png'"
                                                            [alt]="item.name" />
                                                    </div>
                                                    <span class="coin_short_name"> {{ item.name }}</span>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </form>
            <button *ngIf="nonPEPSelection" class="add-more-btn mt-2" (click)="addNotPEP()">
                <em class="flaticon-plus-1"></em> Add More
            </button>
            <ng-template *ngTemplateOutlet="infoLink"></ng-template>
        </div>

        <div class="d-flex justify-content-center mx-auto gap-2 flex-sm-row flex-column">
            <button class="confirm-modal-btn btn-xl btn btn-generate" (click)="back()">Back</button>
            <button type="submit" (click)="taskCheckPEPYes()" class="confirm-modal-btn btn-xl btn-default">Done</button>
        </div>
    </div>
</ng-container>
