export const FEE_STRUCTURE_SPOT_TRADING_TABLE_COLUMNS = [
    {
        _id: 1,
        columnDef: 'level',
        header: 'FEE_STRUCTURE.TRADING.SPOT_TRADING_TABS.LEVEL',
        css: {
            headerPanelClasses: [],
            columnPanelClasses: []
        },
        isSticky: false,
        disableSortClear: false,
        customTemplateRef: 'spotTradingLevel',
        columnPosition: 1,
        noDataPlaceholder: '~',
        canCopy: false,
        hideColumnOnMerge: false,
        hideColumnAlways: false
    },
    {
        _id: 2,
        columnDef: 'minimum_btc_next_level',
        header: 'FEE_STRUCTURE.TRADING.SPOT_TRADING_TABS.TRADE_VOLUME_BTC',
        css: {
            headerPanelClasses: [],
            columnPanelClasses: []
        },
        isSticky: false,

        disableSortClear: false,
        customTemplateRef: 'spotTrading30Day',
        columnPosition: 2,
        noDataPlaceholder: '~',
        canCopy: false,
        hideColumnOnMerge: false,
        hideColumnAlways: false
    },
    {
        _id: 3,
        columnDef: 'maker_commission_perc',
        header: 'FEE_STRUCTURE.TRADING.SPOT_TRADING_TABS.MAKER_TAKER',
        css: {
            headerPanelClasses: [],
            columnPanelClasses: []
        },
        isSticky: false,
        isSortActive: false,
        disableSortClear: false,
        customTemplateRef: 'spotTradingMakerTaker',
        columnPosition: 3,
        noDataPlaceholder: '~',
        canCopy: false,
        hideColumnOnMerge: false,
        hideColumnAlways: false
    }
];
