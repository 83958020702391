import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-card-fee',
    templateUrl: './card-fee.component.html',
    styleUrls: ['./card-fee.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterLink]
})
export class CardFeeComponent implements OnInit {
    CDN_URL = environment.CDN_URL;
    constructor() {}

    ngOnInit() {}
}
