import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { IxfiThemeService } from 'header';
import { LOTTIE_FILES } from 'src/app/core/constants/enum.lottie';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
    isDarkMode: boolean = false;
    isBrowser!:boolean
    subscription: Subscription;
    constructor(private theme: IxfiThemeService,@Inject(PLATFORM_ID) private platformId:object) {
        this.isBrowser = isPlatformBrowser(this.platformId)
    }

    options = LOTTIE_FILES.LOADER;
    CDN = environment.CDN_URL

    ngOnInit(): void {
        this.subscription = this.theme.themeObs.subscribe({
            next: response => (this.isDarkMode = response)
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}