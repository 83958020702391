export const FEE_STRUCTURE_DEPOSIT_TABLE_COLUMNS = [
    {
        _id: 1,
        columnDef: 'coin_code',
        header: 'FEE_STRUCTURE.TABS.COIN_TOKEN',
        css: {
            headerPanelClasses: [],
            columnPanelClasses: []
        },
        isSticky: false,
        disableSortClear: false,
        customTemplateRef: 'depositFeeCoinToken',
        columnPosition: 1,
        noDataPlaceholder: '~',
        canCopy: false,
        hideColumnOnMerge: false,
        hideColumnAlways: false
    },
    {
        _id: 2,
        columnDef: 'coin_name',
        header: 'FEE_STRUCTURE.TABS.FULL_NAME',
        css: {
            headerPanelClasses: [],
            columnPanelClasses: []
        },
        isSticky: false,

        disableSortClear: false,
        customTemplateRef: 'depositFeeFullName',
        columnPosition: 2,
        noDataPlaceholder: '~',
        canCopy: false,
        hideColumnOnMerge: true,
        hideColumnAlways: false
    },
    {
        _id: 3,
        columnDef: 'network_name',
        header: 'FEE_STRUCTURE.TABS.NETWORK',
        css: {
            headerPanelClasses: [],
            columnPanelClasses: []
        },
        isSticky: false,
        isSortActive: false,
        disableSortClear: false,
        customTemplateRef: 'depositFeeNetwork',
        columnPosition: 3,
        noDataPlaceholder: '~',
        canCopy: false,
        hideColumnOnMerge: true,
        hideColumnAlways: false
    },
    {
        _id: 4,
        columnDef: 'deposit_fee',
        header: 'FEE_STRUCTURE.TABS.DEPOSIT_FEE',
        css: {
            headerPanelClasses: [],
            columnPanelClasses: []
        },
        isSticky: false,
        disableSortClear: false,
        customTemplateRef: 'depositFeeDeposit',
        columnPosition: 4,
        noDataPlaceholder: '~',
        canCopy: false,
        hideColumnOnMerge: false,
        hideColumnAlways: false
    },
    {
        _id: 5,
        columnDef: 'withdraw_fee',
        header: 'FEE_STRUCTURE.TABS.WITH_FEE',
        css: {
            headerPanelClasses: [],
            columnPanelClasses: []
        },
        isSticky: false,
        disableSortClear: false,
        customTemplateRef: 'depositNetworkFee',
        columnPosition: 5,
        noDataPlaceholder: '~',
        canCopy: false,
        hideColumnOnMerge: false,
        hideColumnAlways: false
    }
];
