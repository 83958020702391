import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Meta, TransferState } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule, PageTitleService } from './core';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { translateBrowserLoaderFactory } from './translate-browser.loader';
import { ChunkErrorHandler } from 'src/chunkError';
import { UrlSerializer } from '@angular/router';
import { CustomUrlSerializer } from 'src/url-serilizer';
import { TimerService } from './core/services/timer.service';

@NgModule({
    declarations: [AppComponent],
    imports: [
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateBrowserLoaderFactory,
                deps: [HttpClient, TransferState]
            }
        }),
        CoreModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        BrowserAnimationsModule
    ],
    bootstrap: [AppComponent],
    providers: [
        Meta,
        TimerService,
        { provide: ErrorHandler, useClass: ChunkErrorHandler },
        { provide: UrlSerializer, useClass: CustomUrlSerializer }
    ]
})
export class AppModule {
    constructor(private title: PageTitleService) {}
}
