import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { STORAGE_KEY } from 'src/app/core/constants/enum.constants';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../core';
import { IxfiCurrencyService, IxfiLanguageService, IxfiThemeService } from 'header';
import { StorageService } from 'src/app/shared/services/storage.service';
import { IntercomService } from 'src/app/shared/services/intercom.service';

@Component({
    selector: 'app-shell-mobile',
    templateUrl: './shell-mobile.component.html'
})
export class ShellMobileComponent {
    token;
    isMobile;
    isDarkMode: string;
    constructor(
        route: ActivatedRoute,
        theme: IxfiThemeService,
        language: IxfiLanguageService,
        currency: IxfiCurrencyService,
        auth: AuthenticationService,
        storage: StorageService,
        private intercomService: IntercomService
    ) {
        // clear previous data
        storage.clear();

        this.token = route.snapshot.queryParams[STORAGE_KEY.TOKEN] || null;
        this.isMobile = route.snapshot.queryParams[STORAGE_KEY.IS_MOBILE] || 'false';
        this.isDarkMode = route.snapshot.queryParams['  '] || 'false';

        if (!['true', 'false'].includes(this.isMobile)) {
            this.isMobile = 'false';
        }
        if (!['true', 'false'].includes(this.isDarkMode)) {
            this.isDarkMode = 'false';
        }

        if (JSON.parse(this.isMobile)) {
            storage.setItem(STORAGE_KEY.IS_MOBILE, JSON.stringify(true));
            this.intercomService.shutDownChat();
        }

        /**
         * Update Theme
         */
        theme.toggleTheme(JSON.parse(this.isDarkMode));

        if (this.token) {
            storage.setItem(STORAGE_KEY.IS_LOGGED_IN, JSON.stringify(true), undefined, '/', environment.DOMAIN, true);
            storage.setItem(STORAGE_KEY.TOKEN, this.token, undefined, '/', environment.DOMAIN, true);
            auth.loginStatus.next(true);
        }
        if (route.snapshot.queryParams[STORAGE_KEY.IS_PROFILE_COMPLETE]) {
            storage.setItem(
                STORAGE_KEY.IS_PROFILE_COMPLETE,
                route.snapshot.queryParams[STORAGE_KEY.IS_PROFILE_COMPLETE]
            );
        }

        /**
         * Update Language and Currency from Language Code and Currency Symbol
         */
        const languageCode = route.snapshot.queryParams['language_code'];
        if (languageCode) language.updateLanguageByCode(languageCode);

        const currencySymbol = route.snapshot.queryParams['currency_symbol'];
        if (currencySymbol) currency.updateCurrencyBySymbol(currencySymbol);
    }
}
