import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IntercomService } from '../../../shared/services/intercom.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-admin-restricted',
    templateUrl: './admin-restricted.component.html',
    styleUrls: ['./admin-restricted.component.scss']
})
export class AdminRestrictedComponent implements OnInit, OnDestroy {
    public CDN = environment.CDN_URL;
    errorCode;
    subscription!: Subscription;

    public restrictedContent = {
        7723: {
            title: 'Security Alert',
            image: this.CDN + 'auth/kyc-reject-gfx.svg',
            text_1: 'Unfortunately, we are unable to accept your SMS OTP request at this time for your account security.',
            text_2: 'Please retry in sometime or contact our support team for further assistance. Please share the following code with support team -',
            support_button: 'Connect with support',
            back_button: 'Back',
            is_back_button: true
        },
        9723: {
            title: 'Compliance Action',
            image: this.CDN + 'auth/kyc-reject-gfx.svg',
            text_1: 'Unfortunately, we are unable to accept your login request at this time.',
            text_2: 'Please feel free to contact our support team for further assistance. Please share the following code with support team representative - ',
            support_button: 'Connect with support',
            is_back_button: false
        },
        8723: {
            title: 'Compliance Action',
            image: this.CDN + 'auth/kyc-reject-gfx.svg',
            text_1: 'Unfortunately, we are unable to accept your registration request at this time.',
            text_2: 'Please feel free to contact our support team for further assistance. Please share the following code with support team representative - ',
            support_button: 'Connect with support',
            is_back_button: false
        }
    };

    private location = inject(Location);
    private intercomService = inject(IntercomService);
    private activateRoute = inject(ActivatedRoute);
    private router = inject(Router);
    private modal = inject(NgbModal);

    showMessage() {
        this.intercomService.showMessage();
    }
    ngOnInit(): void {
        this.modal.dismissAll();

        this.subscription = this.activateRoute.queryParams.subscribe((params: any) => {
            if (params) {
                this.errorCode = params.q;
                this.location.replaceState('restricted');
            }
            if (!this.errorCode) {
                this.router.navigate(['/auth/login']);
            }
        });
    }
    back() {
        this.location.back();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
