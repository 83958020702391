import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TradeWidgetsCacheService {
    private cacheObjectMap = new Map();

    setKeyValueIntoMap(key, value) {
        this.cacheObjectMap.set(key, value);
    }
    getKeyValue(key) {
        return this.cacheObjectMap.get(key);
    }
    isKeyExisting(key) {
        return this.cacheObjectMap.has(key);
    }
    deleteKeyValue(key) {
        this.cacheObjectMap.delete(key);
    }
    clearCacheObjectMap() {
        this.cacheObjectMap.clear();
    }
}
