export const FEE_STRUCTURE_LIQUIDITY_TABLE_COLUMNS = [
    {
        _id: 1,
        columnDef: 'level_vip',
        header: 'FEE_STRUCTURE.LIQUID_SWAP.COLUMNS.LEVEL',
        css: {
            headerPanelClasses: [],
            columnPanelClasses: []
        },
        isSticky: false,
        disableSortClear: false,
        customTemplateRef: 'liquidityLevel',
        columnPosition: 1,
        noDataPlaceholder: '~',
        canCopy: false,
        hideColumnOnMerge: false,
        hideColumnAlways: false
    },
    {
        _id: 2,
        columnDef: 'base_symbol',
        header: 'FEE_STRUCTURE.LIQUID_SWAP.COLUMNS.POOL',
        css: {
            headerPanelClasses: [],
            columnPanelClasses: []
        },
        isSticky: false,

        disableSortClear: false,
        customTemplateRef: 'liquidityPool',
        columnPosition: 2,
        noDataPlaceholder: '~',
        canCopy: false,
        hideColumnOnMerge: false,
        hideColumnAlways: false
    },
    {
        _id: 3,
        columnDef: 'maker_commission_perc',
        header: 'FEE_STRUCTURE.LIQUID_SWAP.COLUMNS.FEE',
        css: {
            headerPanelClasses: [],
            columnPanelClasses: []
        },
        isSticky: false,
        isSortActive: false,
        disableSortClear: false,
        customTemplateRef: 'liquidityFee',
        columnPosition: 3,
        noDataPlaceholder: '~',
        canCopy: false,
        hideColumnOnMerge: false,
        hideColumnAlways: false
    }
];
