<div
    *ngIf="data.data?.length > 0"
    class="pagination-new d-flex align-items-center justify-content-center justify-content-lg-between pt-3 px-4">
    <div class="pagination-text d-none d-lg-block" *ngIf="!hidePaginationText">
        Showing data {{ data.page * data.pageSize - data.pageSize + 1 }} to
        {{ data.page * data.pageSize > data.collectionSize ? data.collectionSize : data.page * data.pageSize }} of
        {{ data.collectionSize }} entries
    </div>
    <div class="pagination-count ps-0">
        <ngb-pagination
            *ngIf="data.data.length >= data.pageSize || (data?.serverSide ? true : false)"
            [maxSize]="3"
            [rotate]="true"
            [(page)]="data.page"
            [pageSize]="data.pageSize"
            [collectionSize]="data.collectionSize"
            class="pagination-list d-flex justify-content-lg-end gap-2 p-0 mb-0"
            (pageChange)="onChange($event)">
        </ngb-pagination>
    </div>
</div>
