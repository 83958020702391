import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { CustomTemplatesComponent } from './templates/custom-templates/custom-templates.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomDataTableComponent } from './data-table/custom-data-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatRippleModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CustomTemplatesService } from './services/custom-templates.service';

import { LottieModule } from 'ngx-lottie';
import { TemplateRegistrationPipe } from './pipes/templateRegistration/template-registration.pipe';

@NgModule({
    declarations: [CustomDataTableComponent, CustomTemplatesComponent, TemplateRegistrationPipe],
    imports: [
        CommonModule,
        MatTableModule,
        MatFormFieldModule,
        MatInputModule,
        MatPaginatorModule,
        MatSortModule,
        MatIconModule,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatCheckboxModule,
        MatProgressBarModule,
        ClipboardModule,
        MatRippleModule,
        ReactiveFormsModule,
        MatSelectModule,
        TranslateModule,
        LottieModule
    ],
    providers: [CustomTemplatesService],
    exports: [CustomDataTableComponent, TemplateRegistrationPipe]
})
export class CustomDataTableModule { }
