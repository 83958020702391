import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LottieModule } from 'ngx-lottie';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-new-release-modal',
    templateUrl: './new-release-modal.component.html',
    styleUrls: ['./new-release-modal.component.scss'],
    standalone: true,
    imports: [CommonModule, LottieModule]
})
export class NewReleaseModalComponent {
    @Input() data: NgbModalRef;

    CDN_URL = environment.CDN_URL;
    options = { path: 'assets/json/update_web.json' };
    onCancel() {
        this.data.close();
    }
}
