import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../http/api.service';

@Injectable({
    providedIn: 'root'
})
export class BinanceApiService implements OnDestroy {
    private topPairsDataUpdated = new Subject<{ marketTopPairs: any[] }>();

    private spotFavoritePairsListUpdated = new Subject<{ spotPairs: any[] }>();
    subscriptions: Subscription[] = [];

    constructor(private api: ApiService) {}

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    /**
     *
     ** Used to get Markets' Top Pairs
     *
     * @returns It returns Market Top Trading Pairs
     *
     **/
    getMarketTopCharts() {
        const pairGraphSub = this.api
            .post('coin-pairs/market-page-graph')
            .pipe(
                map(marketTopPairsData => {
                    return {
                        marketTopPairs: marketTopPairsData['data']
                    };
                })
            )
            .subscribe(response => {
                this.topPairsDataUpdated.next({
                    marketTopPairs: response.marketTopPairs
                });
            });
        this.subscriptions.push(pairGraphSub);
    }

    /**
     *
     ** Used to get Markets' Spot Pairs Data
     *
     * @returns It returns Market Spot Trading Pairs Observable
     *
     **/
    getMarketTopChartsUpdated() {
        return this.topPairsDataUpdated.asObservable();
    }

    /**
     *
     ** Used to get Markets' Spot Pairs Data
     *
     * @param {*} quoteAsset It contains Market type name
     *
     * @returns It returns Market Spot Trading Pairs
     *
     **/

    getMarketSpotCoinsPairs2(quoteAsset?: string) {
        return this.api.post('coin-pairs/market-pairs', { quoteAsset }).pipe(
            map(spotPairsData => {
                return {
                    spotPairs: spotPairsData?.data['docs'] || [],
                    spotPairsFIAT: spotPairsData?.data['coin_pair_fiat'] || []
                };
            })
        );
    }

    /**
     *
     ** Used to get Depth Data
     *
     * @param {*} symbol It contains Symbol name
     *
     * @param {*} limit It contains data limit
     *
     * @returns It returns Trade depth data `${buyBook}` & `${sellBook}`
     *
     **/

    binanceKlineDataForValidation(symbol: string, interval: string, limit: number) {
        const limitData = limit ? `&limit=${limit}` : '';
        return this.api.get(`binance/klines?symbol=${symbol}&interval=${interval}${limitData}`);
    }
    /**
     *
     * REMOVE THIS
     ** Used to add favorite coin pairs
     *
     * @returns It returns void
     *
     **/
    addFavoriteSymbol(symbolId: any, isFuturePair: boolean) {
        const requestBody: any = { is_futures_coin_pair: isFuturePair };
        return this.api.put(`${API_END_POINTS_BINANCE.ADD_FAV_COIN_PAIR}/${symbolId}`, requestBody);
    }

    /**
     *
     ** Used to remove favorite coin pairs
     *
     * @returns It returns void
     *
     **/
    removeFavoriteSymbol(symbolId: any, isFuturePair: boolean) {
        const requestBody: any = { is_futures_coin_pair: isFuturePair };
        return this.api.put(`${API_END_POINTS_BINANCE.REMOVE_FAV_COIN_PAIR}/${symbolId}`, requestBody);
    }

    /**
     * NOTE: This function will be removed. Use the `fetchFavoriteMarketPairs()` in marketPair service
     *
     * @returns It returns spot favorite coin pairs list
     *
     **/
    getSpotFavoritePairs() {
        const favPairSub = this.api
            .get(API_END_POINTS_BINANCE.FAV_COIN_PAIRS)
            .pipe(
                map(favoritesList => {
                    return {
                        favoriteSymbolsList: favoritesList['data'] || []
                    };
                })
            )
            .subscribe(response => {
                this.spotFavoritePairsListUpdated.next({
                    spotPairs: response.favoriteSymbolsList
                });
            });
        this.subscriptions.push(favPairSub);
    }

    /**
     *
     ** Used to get spot favorite pairs
     *
     * @returns It returns It returns spot favorite coin pairs Observable
     *
     **/
    getSpotFavoritePairsUpdated() {
        return this.spotFavoritePairsListUpdated.asObservable();
    }
}

export enum API_END_POINTS_BINANCE {
    SPOT_COIN_PAIRS = 'binance-coin-pairs/spot-coin-pairs',
    FUTURE_COIN_PAIRS = 'v1/binance-coin-pairs/futures-fav-coin-pair',
    FAV_COIN_PAIRS = 'v1/coin/get-fav-coin-pair',
    REMOVE_FAV_COIN_PAIR = 'v1/coin/remove-fav-coin-pair',
    ADD_FAV_COIN_PAIR = 'v1/coin/add-fav-coin-pair'
}
