import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { ReferralService } from 'src/app/profile-new/referral/referral.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ReferralUrlResolver implements OnDestroy  {
    subscription: Subscription;
    constructor(private referralService: ReferralService, @Inject(PLATFORM_ID) private platformId: Object) {}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (state.url.includes('share') && isPlatformBrowser(this.platformId)) {
            const url = state.url.split('/');
            this.subscription = this.referralService.getRedirectURLforCustomReferral(url[url.length - 1]).subscribe({
                next: res => {
                    if (res.dynamic_link) {
                        localStorage.setItem('refCodeEdit', 'true');
                        window.open(res.dynamic_link, '_self');
                    } else window.open(environment.IXFI_EXCHANGE, '_self');
                    return of(true);
                },
                error: err => {
                    return of(true);
                },
                complete: () => {
                    return of(true);
                }
            });
        }
        return of(true);
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}
