import { Injectable } from '@angular/core';
import { STORAGE_KEY } from 'src/app/core/constants/enum.constants';
import { GeetestCaptcha } from 'src/app/shared/models/geetest-captcha.model';

declare let initGeetest4: Function;

/**
 * @description A captcha service which helps us to register and validate captcha.
 * @since 1.0.0
 */
@Injectable({
    providedIn: 'root'
})
export class CaptchaV4Service {
    public geetest: any = null;

    /**
     * @description Register Captcha.
     * @returns {void}.
     */
    public initGeetest(id): void {
        const web: any = this;
        initGeetest4(
            {
                captchaId: id,
                product: STORAGE_KEY.BIND,
                lang: STORAGE_KEY.DEFAULT_LANG,
                width: STORAGE_KEY.HUNDRED_PERCENTAGE
            },
            function (captcha) {
                web.geetest = captcha;
                captcha
                    .onReady(() => {
                        //You can't call the show box method to display the captcha until captcha is ready
                    })
                    .onSuccess(() => {})
                    .onError(() => {})
                    .onClose(() => {})
                    .destroy();
            }
        );
    }

    public showBox(id): Promise<GeetestCaptcha | null> {
        this.geetest.showBox();
        return new Promise((resolve, reject) => {
            this.geetest.onSuccess(() => {
                const resultData: GeetestCaptcha = this.geetest.getValidate();
                if (resultData) resolve(resultData);
                else reject(null);
            });
            this.geetest?.onError(() => {
                reject(null);
                this.initGeetest(id);
            });
            this.geetest?.onClose(() => {
                reject(null);
                this.geetest?.destroy();
                this.initGeetest(id);
            });
        });
    }
}
