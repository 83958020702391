<ng-container *ngIf="!isWebView">
    <div class="cookie-consent" *ngIf="!cookieConsent">
        <div class="img-align">
            <img
                [src]="CDN + 'cookies.svg'"
                alt="cookies_img"
                width="50"
                height="50"
                id="cookies_img"
                class="cookies_img" />
        </div>
        <p>
            {{ 'COOKIE_POLICY.COOKIE_DESCRIPTION' | translate }}
            <a class="mr-1" [routerLink]="['/static-pages/page/' + slugList['privacy-policy']]">
                {{ 'COOKIE_POLICY.COOKIE_DESCRIPTION_COOKIES' | translate }}
            </a>
            {{ 'COOKIE_POLICY.COOKIE_DESCRIPTION_END' | translate }}
        </p>
        <div class="g-2 d-flex align-items-center justify-content-between">
            <button class="cookie-btn btn-default" (click)="setCookie()">
                {{ 'COOKIE_POLICY.OK_TEXT' | translate }}
            </button>
        </div>
    </div>
    <ng-container *ngIf="isMobileDevice && showDownload">
        <div class="d-lg-none">
            <div class="download-app open-animation" #toastElement>
                <div class="img-align">
                    <img [src]="CDN + 'cookies/ixfi-logo-img.png'" alt="ixfi-logo" class="img-fluid cookies_img" />
                </div>
                <div class="app-content-area">
                    <div>
                        <p class="app-text">{{ 'DOWNLOAD_APP_COOKIE.TITLE' | translate }}</p>
                        <p class="sub-title">
                            {{ 'DOWNLOAD_APP_COOKIE.DESCRIPTION' | translate }}
                        </p>
                    </div>
                    <a class="download-button" href="https://share.ixfi.com/downloadapp" target="_blank">
                        {{ 'DOWNLOAD_APP_COOKIE.BUTTON_TEXT' | translate }}
                    </a>
                </div>
                <div class="close-app-btn">
                    <button type="button" class="close ml-2" (click)="onClose()" aria-label="Close">
                        <em class="flaticon-close"></em>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
