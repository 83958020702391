import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core';
import { FourNotFourComponent } from './core/components/four-not-four/four-not-four.component';
import { ServerManagementComponent } from './core/components/server-management/server-management.component';
import { AppResolver } from './core/services/app.resolver';
import { ShellComponent } from './core/components/shell/shell.component';
import { ShellMobileComponent } from './core/components/shell-mobile/shell-mobile.component';
import { FeeStructureComponent } from './core/components/fee-structure/fee-structure.component';
import { ReferralUrlResolver } from './standalone/referral-url/resolver/referral-url.resolver';
import { LoaderComponent } from './core/components/loader/loader.component';
import { AdminRestrictedComponent } from './core/components/admin-restricted/admin-restricted.component';
import { CardFeeComponent } from './standalone/components/card-fee/card-fee.component';

import { environment } from 'src/environments/environment';
import { WalletResolver } from './wallet-v2/shared/service/wallet.resolver';

const routes: Routes = [
    {
        path: 'landing',
        redirectTo: '',
        pathMatch: 'full'
    },
    {
        path: 'home/landing',
        redirectTo: '',
        pathMatch: 'full'
    },
    {
        path: 'static-pages',
        redirectTo: '',
        pathMatch: 'prefix'
    },
    {
        path: 'history',
        redirectTo: 'wallet/history/wallet',
        pathMatch: 'full'
    },
    {
        path: 'crypto/buy',
        pathMatch: 'prefix',
        redirectTo: 'buy'
    },
    {
        path: 'buy-crypto',
        pathMatch: 'full',
        redirectTo: 'buy'
    },
    {
        path: 'buy/crypto/history',
        pathMatch: 'full',
        redirectTo: 'buy/crypto/history'
    },
    {
        path: 'buy/crypto',
        pathMatch: 'prefix',
        redirectTo: 'buy'
    },

    {
        path: '',
        resolve: [AppResolver],
        component: ShellComponent,
        children: [
            {
                path: '',
                data: {
                    title: 'IXFI - Buy, Sell, Trade Bitcoin, Ethereum & Altcoins | Crypto Exchange ',
                    metaTags: {
                        og_description:
                            'More than crypto. Experience the gen 3.0 crypto exchange that allows you to buy, sell and trade Bitcoin, Ethereum and altcoins. Earn free crypto and enjoy a unique gamified experience for your everyday crypto activities',
                        og_image: environment.CDN_URL + 'SEO/meta-ixfi.png',
                        keywords:
                            'Crypto Exchange, Cryptocurrency Exchange, Bitcoin Exchange, Buy & Sell Bitcoin, Ethereum'
                    }
                },
                loadChildren: () => import('./landing/landing-new.module').then(m => m.LandingNewModule)
            },
            {
                path: '',
                data: {
                    title: 'Buy Crypto | BTC, ETH, USDT & Altcoins | IXFI',
                    metaTags: {
                        og_description:
                            'Buy Crypto easily on IXFI at the best rates in more than 180 countries by using over 93 local currencies. Explore a wide range of 115+ cryptocurrencies available to purchase with over 10 payment methods.',
                        og_image: environment.CDN_URL + 'meta/Buy-Crypto_Meta-Image.png',
                        keywords: 'IXFI Buy Crypto, Buy Crypto, Buy Bitcoin, Buy Ethereum, Buy Altcoins'
                    }
                },
                loadChildren: () => import('./buy-crypto/buy-crypto.module').then(m => m.BuyCryptoModule)
            },
            {
                path: '',
                data: {
                    title: 'Buy Crypto | BTC, ETH, USDT & Altcoins | IXFI',
                    metaTags: {
                        og_description:
                            'Buy Crypto easily on IXFI at the best rates in more than 180 countries by using over 93 local currencies. Explore a wide range of 115+ cryptocurrencies available to purchase with over 10 payment methods.',
                        og_image: environment.CDN_URL + 'meta/Buy-Crypto_Meta-Image.png',
                        keywords: 'IXFI Buy Crypto, Buy Crypto, Buy Bitcoin, Buy Ethereum, Buy Altcoins'
                    }
                },
                loadChildren: () => import('./buy-crypto/vendors/vendors.module').then(m => m.VendorsModule)
            },
            {
                path: '',
                data: {
                    title: 'Sell Crypto | BTC, ETH, USDT & Altcoins | IXFI',
                    metaTags: {
                        og_description:
                            'Sell Crypto easily on IXFI at the best rates in more than 180 countries by using over 93 local currencies. Explore a wide range of 115+ cryptocurrencies available to purchase with over 10 payment methods.',
                        og_image: environment.CDN_URL + 'meta/Buy-Crypto_Meta-Image.png',
                        keywords: 'Sell Buy Crypto, Sell Crypto, Sell Bitcoin, Sell Ethereum, Sell Altcoins'
                    }
                },
                loadChildren: () => import('./crypto-sell/crypto-sell.module').then(m => m.CryptoSellModule)
            },
            {
                path: 'convert',
                data: {
                    title: 'Fair Price System',
                    metaTags: {
                        og_image: environment.CDN_URL + 'meta/Convert_Meta-Image.png',
                        keywords: 'IXFI Convert, Crypto Convert, Swap Cryptocurrencies'
                    }
                },
                loadChildren: () => import('./convert/convert.module').then(m => m.ConvertModule)
            },
            {
                path: 'market',
                data: {
                    title: 'Cryptocurrency Markets | Crypto Prices & Market Cap | IXFI',
                    metaTags: {
                        og_description:
                            'IXFI crypto market - Discover the latest prices, market cap, token price, charts and 24h change for bitcoin, ethereum and altcoins.',
                        og_image: environment.CDN_URL + 'meta/Market_Meta-Image.png',
                        keywords:
                            'IXFI Spot Markets, Spot Markets, Spot Market, Bitcoin market, Cryptocurrency market, BTC markets, Crypto market, Altcoin market'
                    }
                },
                loadChildren: () => import('./market/market.module').then(m => m.MarketModule)
            },
            {
                path: 'fps',
                data: {
                    title: 'Discover Fair Price System for IXFI Visa Debit Card | IXFI',
                    metaTags: {
                        og_description:
                            'Learn how IXFI’s Fair Price System ensures competitive rates and transparency for all your IXFI Visa Debit Card transactions.',
                        keywords: 'Fair Price System, Transparent Pricing, Crypto Transactions, Competitive Rates'
                    }
                },
                loadChildren: () => import('./fps/fps.module').then(m => m.FpsModule)
            },
            {
                path: 'card-fees',
                data: {
                    title: 'Fee Information for the IXFI Visa Debit Card | IXFI',
                    metaTags: {
                        og_description:
                            'Understand the fee structure for your IXFI Visa Debit Card. Learn about our transparent costs and ensure a smooth transaction experience.',
                        keywords:
                            'IXFI Card, IXFI Visa Debit Card Fees, Visa Card Costs, Fee Structure, Card Transaction Fees'
                    }
                },
                loadChildren: () => import('./card-fee/card-fee.module').then(m => m.CardFeeModule)
            },
            // card-fee Html Route
            {
                path: 'card-fee',
                component: CardFeeComponent,
                data: {
                    title: 'Card Fees'
                }
            },
            // card-fee html route end
            {
                path: 'help-center',
                data: {
                    title: 'Help Center - IXFI',
                    metaTags: {
                        og_description:
                            'Explore the IXFI Help Center for complete support on trading, account management, and more. Get quick answers and resolve your issues efficiently.',
                        og_image: environment.CDN_URL + 'meta/Help-Center_Meta-Image.png',
                        keywords:
                            'IXFI Help Center, Help Center, Crypto Support, Customer Support, Crypto FAQ, Troubleshooting, Account Assistance, Trading Support'
                    }
                },
                loadChildren: () => import('./support/support.module').then(m => m.SupportModule)
            },
            {
                path: 'trade',
                data: {
                    isHideFooter: true,
                    title: 'Trade | IXFI',
                    metaTags: {
                        og_image: environment.CDN_URL + 'meta/Trade_Meta-Image.png',
                        keywords: 'BTC USDT, BTC to USDT, BTC/USDT, BTC trading pair'
                    }
                },
                loadChildren: () => import('./trade/trade.module').then(m => m.TradeModule)
            },
            {
                path: '',
                loadChildren: () => import('./static-pages/static-pages.module').then(m => m.StaticPagesModule)
            },
            {
                path: 'fee-structure',
                data: {
                    title: 'IXFI Fee Structure | Crypto Trading Fees',
                    metaTags: {
                        og_description:
                            'Explore IXFI’s detailed fee structure for deposits, withdrawals, and trading ensuring clarity and trust for users. IXFI offers a competitive fee structure with discounts for high-volume traders.',
                        og_image: environment.CDN_URL + 'meta/Fees_Meta-Image.png',
                        keywords:
                            'IXFI Fees, Fee Structure, Crypto Fees, Transaction Fees, Trading Fees, Transparent Fees, Low Fees'
                    }
                },
                component: FeeStructureComponent
            },
            {
                path: 'fee-table',
                data: {
                    isHideFooter: true,
                    title: 'Fees table | IXFI',
                    metaTags: {
                        og_description:
                            'Trade crypto confidently on IXFI, the exchange that offers fast, secure, and seamless trading.',
                        og_image: environment.CDN_URL + 'meta/Fees_Meta-Image.png'
                    }
                },
                loadChildren: () => import('./core/components/fee-table/fee-table.module').then(m => m.FeeTableModule)
            },
            {
                path: '',
                data: {
                    title: 'Markets, Insights, Heat Maps and Cross Rates | IXFI',
                    metaTags: {
                        og_description:
                            'Discover comprehensive research on cryptocurrency markets. Get detailed insights, heat maps, cross rates and analysis on crypto assets to enhance your trading strategy on IXFI.'
                    }
                },
                loadChildren: () => import('./coin-page/coin-page.module').then(m => m.CoinpageModule)
            },

            // Auth Protected routes
            {
                path: 'home',
                canLoad: [AuthGuard],
                loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
            },

            {
                path: 'wallet',
                data: {
                    title: 'IXFI Crypto Wallet for Bitcoin, Ethereum & Altcoins',
                    metaTags: {
                        og_description:
                            'Manage all your crypto assets in one place and enjoy advanced security features. IXFI offers a modern wallet for Bitcoin, Ethereum, and Altcoins.',
                        keywords:
                            'IXFI Crypto Wallet, Crypto Wallet,  Bitcoin Wallet, Ethereum Wallet, Altcoin Wallet, Secure Crypto Wallet, Crypto Assets Wallet'
                    }
                },
                canLoad: [AuthGuard],
                resolve: [WalletResolver],
                loadChildren: () => import('./wallet-v2/wallet.module').then(m => m.WalletModule)
            },
            {
                path: 'dashboard',
                data: {
                    metaTags: {
                        title: 'Reports | IXFI',
                        og_description:
                            'View advanced reports on your crypto assets. Keep track of your profits and investments effortlessly.',
                        keywords: 'IXFI reports, Crypto Reports, Altcoin Reports, Advanced Crypto Reports'
                    }
                },
                canLoad: [AuthGuard],
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'profile',
                canLoad: [AuthGuard],
                loadChildren: () => import('./profile-new/profile-new.module').then(m => m.ProfileNewModule)
            }
        ]
    },

    {
        path: '',
        resolve: [AppResolver],
        children: [
            {
                path: 'auth',
                loadChildren: () => import('./new-auth/new-auth.module').then(m => m.NewAuthModule)
            }
        ]
    },
    {
        path: 'mobile',
        resolve: [AppResolver],
        component: ShellMobileComponent,
        children: [
            {
                path: 'profile',
                loadChildren: () => import('./profile-new/profile-new.module').then(m => m.ProfileNewModule)
            },
            {
                path: 'home',
                loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
            },
            {
                path: 'static-pages',
                loadChildren: () => import('./static-pages/static-pages.module').then(m => m.StaticPagesModule)
            },
            {
                path: 'fee-structure',
                component: FeeStructureComponent
            },
            {
                path: 'trade',
                loadChildren: () => import('./trade/trade.module').then(m => m.TradeModule)
            },
            {
                path: 'help-center',
                loadChildren: () => import('./support/support.module').then(m => m.SupportModule)
            },
            {
                path: 'dashboard',
                data: {
                    title: 'Reports',
                    og_description:
                        'View advanced reports on your crypto assets. Keep track of your profits and investments effortlessly.',
                    keywords: 'IXFI reports, Crypto Reports, Altcoin Reports, Advanced Crypto Reports'
                },
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
            }
        ]
    },
    {
        path: 'share/:code',
        data: {
            title: 'IXFI Referral Program | Earn Free Crypto',
            metaTags: {
                og_description:
                    "Join the IXFI Referral Program and earn 20% of the trading fees from every friend you invite. It's a win-win for everyone, so start earning today!",
                og_image: environment.CDN_URL + 'meta/Referral_Meta-Image.png'
            }
        },
        resolve: [ReferralUrlResolver],
        component: LoaderComponent
    },
    {
        path: 'server-management',
        component: ServerManagementComponent,
        data: {
            title: 'We are upgrading'
        }
    },
    {
        path: 'restricted',
        component: AdminRestrictedComponent,
        data: {
            title: 'We are upgrading'
        }
    },
    {
        path: '**',
        component: FourNotFourComponent,
        data: {
            title: 'Page not found'
        }
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabledBlocking' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
