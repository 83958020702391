import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
// import { STORAGE_KEY } from 'src/app/core/constants/enum.constants';
// import { ProfileService } from 'src/app/profile-new/shared/services/profile.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
// import { AuthModuleService } from 'src/app/new-auth/auth-module.service';
import { Subscription } from 'rxjs';
// import { StorageService } from 'src/app/shared/services/storage.service';
// import { User } from 'src/app/shared/models/user.model';
@Component({
    selector: 'app-trusted',
    templateUrl: './trusted.component.html',
    styleUrls: ['./trusted.component.scss'],
    standalone: true,
    imports: [TranslateModule, CommonModule]
})
export class TrustedComponent implements OnInit, OnDestroy {
    CDN = environment.CDN_URL;
    @Input() data;
    @Input() isTrusted;
    @Input() type;
    // userData: User;
    // private authService = inject(AuthModuleService);
    // private storageService = inject(StorageService);
    // private profile = inject(ProfileService);
    subscription: Subscription;

    ngOnInit() {
        // this.subscription = this.profile.profileDetails$.subscribe(value => this.userData = value);
    }

    onCancel(status) {
        // if(this.type === 'buyCrypto') {
        //     this.data.close({ status });
        //     return;
        // }

        // Commented out below code to stop the KYC popup from looping.

        // if (this.storageService.check(STORAGE_KEY.RETURN_URL) && this.userData && Object.keys(this.userData).length) {
        // this.authService.nextRoute('', this.userData)
        // }
        this.data.close({ status });
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}
