<div class="container-small flex-wrap">
    <!-- Registration not allowed -->
    <div class="w-100 login-card restricted-content justify-content-center align-items-center text-center">
        <div class="restricted-content-inner">
            <img [src]="restrictedContent[errorCode].image" alt="errorCode" />
            <div>
                <h5 class="danger my-4">{{ restrictedContent[errorCode].title }}</h5>
                <p>
                    {{ restrictedContent[errorCode].text_1 }}
                </p>
                <p>
                    {{ restrictedContent[errorCode].text_2 }}
                    "<strong> {{ errorCode }} </strong> "
                </p>
            </div>
            <button (click)="showMessage()" class="btn btn-default btn-xxl mt-3 w-100">
                {{ restrictedContent[errorCode].support_button }}
            </button>
            <a *ngIf="restrictedContent[errorCode].is_back_button" (click)="back()" class="back-btn">
                <em class="flaticon flaticon-right-arrows"></em>
            </a>
        </div>
    </div>
    <!-- Registration not allowed END-->
</div>
