import { environment } from 'src/environments/environment';

export const LOTTIE_FILES = {
    NEED_HELP: { path: environment.CDN_URL + 'buy-crypto/need-help.json' },
    BUY_CRYPTO_LIGHT: { path: environment.CDN_URL + 'buy-crypto/buy_crypto_light.json' },

    BUY_CRYPTO_DARK: { path: environment.CDN_URL + 'buy-crypto/buy_crypto_dark.json' },
    LOADER: { path: 'assets/images/lottie/app-loader.json' },
    LOGIN: { path: 'assets/images/lottie/login-lottie.json' },
    EMPTY_SEARCH: { path: 'assets/images/lottie/empty-search-results.json' },
    SAVE_LAYOUT: { path: 'assets/images/lottie/save-layout.json' },
    NEWS: { path: 'assets/images/lottie/empty-search-results.json' },
    SUCCESS: {
        loop: false,
        path: 'assets/images/lottie/lottie-success.json'
    },
    CONVERT_SUCCESS: {
        loop: false,
        path: environment.CDN_URL + 'convert/Congratulation.json'
    },
    RESEARCH_LANDING_LIGHT: {
        path: 'assets/images/lottie/research_light.json',
        autoplay: true,
        loop: true
    },
    RESEARCH_LANDING_DARK: {
        path: 'assets/images/lottie/research_dark.json',
        autoplay: true,
        loop: true
    },
    CRYPTO_LANDING_LIGHT: {
        path: 'assets/images/lottie/aggregator_light.json',
        autoplay: true,
        loop: true
    },
    CRYPTO_LANDING_DARK: {
        path: 'assets/images/lottie/aggregator_dark.json',
        autoplay: true,
        loop: true
    },
    CONTACT_US_PAGE: {
        path: environment.CDN_URL + 'contactus/contact-us-aniation.json'
    },
    SUPPORTED_CRYPTO: {
        path: environment.CDN_URL + 'lottie/supported-crypto.json'
    },
    SUPPORTED_CURRENCIES: {
        path: environment.CDN_URL + 'lottie/supported-currencies.json'
    },
    SUPPORTED_COUNTRY: {
        path: environment.CDN_URL + 'lottie/supported-country.json'
    },
    CONVERT_FEES: {
        loop: true,
        path: environment.CDN_URL + 'lottie/0_%25_fees.json'
    },
    CONVERT_HELP: {
        loop: true,
        path: environment.CDN_URL + 'lottie/24_7_help.json'
    },
    CONVERT_LIQUIDITY: {
        loop: true,
        path: environment.CDN_URL + 'lottie/high_liquidity.json'
    },
    BUYCRYPTO_HELP: {
        loop: true,
        path: environment.CDN_URL + 'lottie/buy-crypto/help_support.json'
    },
    BUYCRYPTO_REGION: {
        loop: true,
        path: environment.CDN_URL + 'lottie/buy-crypto/regions.json'
    },
    BUYCRYPTO_NO_OFFER: {
        loop: true,
        path: environment.CDN_URL + 'lottie/shared/sorry.json'
    },
    BUYCRYPTO_NO_DATA: { path: environment.CDN_URL + 'lottie/no_data.json' },
    CONVERT_NO_DATA: { path: environment.CDN_URL + 'lottie/no_data.json' }
};
