import { Injectable, TemplateRef } from '@angular/core';

@Injectable()
export class CustomTemplatesService {
    private templates: TemplateMap = {};

    public add(name: string, ref: TemplateRef<any>): void {
        this.templates[name] = ref;
    }

    public get(name: string): TemplateRef<any> {
        return this.templates[name];
    }
}
interface TemplateMap {
    [key: string]: TemplateRef<any>;
}
