import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { LottieModule } from 'ngx-lottie';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-toast-popup',
    templateUrl: './toast-popup.component.html',
    styleUrls: ['./toast-popup.component.scss'],
    standalone: true,
    imports: [CommonModule, LottieModule]
})
export class ToastPopupComponent extends Toast implements OnInit {
    lottieOptions: any = {
        loop: false,
        path: ''
    };
    @Input() toastType: string;
    toastMessage: string | null | undefined;
    toastTitle: string | undefined;
    constructor(protected toastr: ToastrService, public toastPackage: ToastPackage) {
        super(toastr, toastPackage);
    }

    ngOnInit(): void {
        this.toastMessage = this.toastPackage.message;
        this.toastTitle = this.toastPackage.title;
    }

    // Returns the toast body class based on toast type
    get toastClass(): string {
        const classData = {
            success: 'toast-body-success',
            warning: 'toast-body-wrong',
            error: 'toast-body-unsuccess'
        };
        return classData[this.toastType.toLowerCase()];
    }

    // Returns the src attribute of the toast <img> element
    get toastImage(): any {
        const imageData = {
            success: '/assets/images/lottie/lottie-success.json',
            warning: '/assets/images/lottie/lottie-warning.json',
            error: '/assets/images/lottie/lottie-error.json'
        };
        this.lottieOptions.path = imageData[this.toastType.toLowerCase()];
        return this.lottieOptions;
    }
}
