import { Pipe, PipeTransform, TemplateRef } from '@angular/core';
import { CustomTemplatesService } from '../../services/custom-templates.service';

@Pipe({
    name: 'registerTemplate',
    pure: true
})
export class TemplateRegistrationPipe implements PipeTransform {
    constructor(private templateService: CustomTemplatesService) { }
    transform(templateRef: TemplateRef<any>, templateName: string): void {
        this.templateService.add(templateName, templateRef);
    }
}
