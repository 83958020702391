<!-- Start: Custom Tempalte Loader -->
<ng-template
    #customTemplate
    let-customTemplate="value"
    let-merged="merged"
    let-column="column"
    let-column2="column2"
    let-value2="value2"
    let-element="element">
    <div class="custom-templates-flex">
        <span>
            <ng-container>
                <ng-container
                    *ngTemplateOutlet="
                        column.customTemplateRef
                            ? templatesService.get(column.customTemplateRef)
                            : templatesService.get('default');
                        context: { value: customTemplate, column: column, merged: merged, element: element }
                    "></ng-container>
            </ng-container>
        </span>
        <!-- // value seprator  -->
        <span>{{ merged ? ' / ' : '' }}</span>

        <!-- // value seprator  -->
        <span>
            <ng-container *ngIf="merged">
                <ng-container
                    *ngTemplateOutlet="
                        column2.customTemplateRef
                            ? templatesService.get(column2.customTemplateRef)
                            : templatesService.get('default');
                        context: { value: value2, column: column2, merged: false, element: element }
                    "></ng-container>
            </ng-container>
        </span>
    </div>
</ng-template>
{{ customTemplate | registerTemplate: 'customTemplate' }}
<!-- End: Custom Tempalte Loader-->

<!-- Start: Default value -->
<ng-template #default let-defaultVal="value">
    {{ defaultVal }}
</ng-template>
{{ default | registerTemplate: 'default' }}
<!-- End: Default value -->

