<!-- // Register custom templates  -->
<app-custom-templates></app-custom-templates>

<div class="ixfi-mat-table-wrapper">
    <div *ngIf="showSearch" class="ixfi-mat-table-search-wrapper">
        <mat-form-field appearance="outline">
            <mat-icon matPrefix>search</mat-icon>
            <input
                name="searchFilter"
                [(ngModel)]="searchFilter"
                matInput
                (ngModelChange)="applyFilter($event)"
                #input />
        </mat-form-field>
    </div>
    <div class="ixfi-mat-table-container">
        <table
            aria-describedby=""
            matColumnGap="20%"
            mat-table
            [dataSource]="dataSource"
            multiTemplateDataRows
            matSort
            [matSortActive]="activeSortColumn"
            [matSortDirection]="matSortDirection"
            (matSortChange)="handleSortChange($event)"
            [trackBy]="trackByFn">
            <ng-container
                [matColumnDef]="column.columnDef"
                *ngFor="let column of dynamicColumns; trackBy: trackByFn"
                [sticky]="column.isSticky">
                <ng-container>
                    <!-- // Header START -->
                    <ng-container *ngIf="column.isSortActive">
                        <ng-container>
                            <th
                                scope=""
                                class="ixfi-mat-table-sort-header"
                                mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header
                                [disableClear]="column.disableSortClear"
                                [ngClass]="column.css.headerPanelClasses">
                                <ng-container
                                    *ngIf="
                                        hideColumnsOnResponsive &&
                                            column?.mergeWithId &&
                                            getColumnNameById(column?.mergeWithId)?.header;
                                        else singleHeader
                                    ">
                                    <span>
                                        {{ column.header | translate }} <br />
                                        <span>{{ getColumnNameById(column?.mergeWithId)?.header | translate }}</span>
                                    </span>
                                </ng-container>

                                <ng-template #singleHeader>{{ column.header | translate }} </ng-template>
                            </th>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!column.isSortActive" [ngClass]="column.css.headerPanelClasses">
                        <th scope="" mat-header-cell *matHeaderCellDef>
                            <ng-container
                                *ngIf="
                                    hideColumnsOnResponsive &&
                                        column?.mergeWithId &&
                                        getColumnNameById(column?.mergeWithId)?.header;
                                    else singleHeader
                                ">
                                <span>
                                    {{ column.header | translate }} <br />
                                    <span>{{ getColumnNameById(column?.mergeWithId)?.header | translate }}</span>
                                </span>
                            </ng-container>

                            <ng-template #singleHeader>{{ column.header | translate }} </ng-template>
                        </th>
                    </ng-container>
                    <!-- // Header END -->
                </ng-container>

                <!-- // For mobile  -->

                <td #tdValue mat-cell *matCellDef="let element" [ngClass]="column.css.columnPanelClasses">
                    <span class="flex">
                        <ng-container
                            *ngTemplateOutlet="
                                templateService.get('customTemplate');
                                context: hideColumnsOnResponsive && column?.mergeWithId
                                    ? {
                                          merged: true,
                                          value:
                                              element[column.columnDef] === null
                                                  ? column.noDataPlaceholder
                                                  : element[column.columnDef],
                                          value2:
                                              element[getColumnNameById(column.mergeWithId).columnDef] === null
                                                  ? getColumnNameById(column.mergeWithId).noDataPlaceholder
                                                  : element[getColumnNameById(column.mergeWithId).columnDef],
                                          element: element,
                                          column: column,
                                          column2: getColumnNameById(column.mergeWithId)
                                      }
                                    : {
                                          merged: false,
                                          value:
                                              element[column.columnDef] === null
                                                  ? column.noDataPlaceholder
                                                  : element[column.columnDef],
                                          element: element,
                                          column: column
                                      }
                            "></ng-container>
                    </span>

                    <mat-icon
                        matRipple
                        [matRippleUnbounded]="true"
                        [matRippleCentered]="true"
                        [cdkCopyToClipboard]="getTextToCopy(tdValue?.innerText)"
                        (cdkCopyToClipboardCopied)="cdkCopyToClipboardCopied($event)"
                        class="mat-table-copy-icon"
                        *ngIf="column.canCopy">
                        file_copy
                    </mat-icon>
                </td>
            </ng-container>

            <!-- // Merge columns -->

            <!-- // Expand arrow row  -->
            <ng-container matColumnDef="expand">
                <th scope="" mat-header-cell *matHeaderCellDef aria-label="row actions">
                    {{ expandColumnName | translate }}
                </th>
                <td class="text-center" mat-cell *matCellDef="let element">
                    <button
                        mat-icon-button
                        aria-label="expand row"
                        (click)="
                            expandedElement = expandedElement === element ? null : element; $event.stopPropagation()
                        ">
                        <ng-container *ngIf="!expandDownArrowTemplate">
                            <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                        </ng-container>
                        <ng-container *ngIf="expandDownArrowTemplate">
                            <ng-container *ngIf="expandedElement !== element">
                                <ng-container
                                    *ngTemplateOutlet="
                                        expandDownArrowTemplate;
                                        context: { element: element }
                                    "></ng-container>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="!expandUpArrowTemplate">
                            <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                        </ng-container>
                        <ng-container *ngIf="expandUpArrowTemplate">
                            <ng-container *ngIf="expandedElement === element">
                                <ng-container *ngTemplateOutlet="expandUpArrowTemplate"></ng-container>
                            </ng-container>
                        </ng-container>
                    </button>
                </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div
                        *ngIf="isExpandable"
                        class="expandable-row-element-detail"
                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <ng-container *ngTemplateOutlet="expandViewTemplate; context: { element: element }">
                        </ng-container>
                    </div>
                </td>
            </ng-container>

            <tr class="header" mat-header-row *matHeaderRowDef="displayedColumns; sticky: isHeaderSticky"></tr>

            <tr
                *matRowDef="let element; columns: displayedColumns"
                [class.outer-row-4n]="isExpandable"
                [class.outer-row-2n]="!isExpandable"
                mat-row
                [ngClass]="rowPanelClass"
                [class.expandable-row-expanded-row]="expandedElement === element"></tr>

            <ng-container *ngIf="isExpandable">
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-row-detail-row"></tr>
            </ng-container>

            <!-- Row shown when there is no data. -->
            <ng-container *ngIf="!isLoading">
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell noDataRow" [attr.colspan]="displayedColumns.length">
                        <div class="p-3">
                            <div class="mb-1 no-data-lottie">
                                <ng-lottie [options]="options" width="100%" height="100%" class=" "> </ng-lottie>
                            </div>
                            {{ noDataLabel }}
                        </div>
                    </td>
                </tr>
            </ng-container>
        </table>

        <!-- // Progress Bar  -->
        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    </div>
    <mat-paginator
        [style.display]="hideDefaultPaginator ? 'none' : 'block'"
        [pageSizeOptions]="pageSizeOptions"
        [hidePageSize]="hidePageSize"
        [pageIndex]="page_index || 0"
        (page)="handlePageEvent($event)"
        showFirstLastButtons
        class="ixfi-mat-custom-paginator"></mat-paginator>
</div>
