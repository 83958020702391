import { Component, Input, Output, inject, EventEmitter } from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-common-pagination',
    standalone: true,
    imports: [CommonModule, NgbPaginationModule],
    templateUrl: './common-pagination.component.html'
})
export class CommonPaginationComponent {
    private scroller = inject(ViewportScroller);
    @Input() data;
    @Input() hidePaginationText: boolean = false;
    @Input() scrollDetail: {
        position;
        tableId: string;
    };
    @Output() change: EventEmitter<any> = new EventEmitter();

    onChange(event) {
        if (this.scrollDetail) {
            this.scroller.setOffset(this.scrollDetail.position);
            this.scroller.scrollToAnchor(this.scrollDetail.tableId);
        } else {
            this.scroller.scrollToPosition([0, 0]);
        }
        this.change.emit(event);
    }
}
