function testa() {}
testa.prototype.openReferalPage = function openReferalPage(data, a) {
    let userAgent = window.navigator.userAgent.toLowerCase(),
        ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
        window.webkit.messageHandlers.openReferalPage.postMessage({ status: 'ready' });
    } else {
        IXFIAndroid.openReferalPage('referral');
    }
};
testa.prototype.completeProfile = function completeProfile(data, a) {
    let userAgent = window.navigator.userAgent.toLowerCase(),
        ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
        window.webkit.messageHandlers.completeProfile.postMessage({ status: 'ready' });
    } else {
        IXFIAndroid.openReferalPage('completeProfile');
    }
};
testa.prototype.buyNowBuyCrypto = function buyNowBuyCrypto(data, a) {
    let userAgent = window.navigator.userAgent.toLowerCase(),
        ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
        window.webkit.messageHandlers.buyNowBuyCrypto.postMessage({ status: 'ready' });
    } else {
        IXFIAndroid.openReferalPage('BuyNow');
    }
};
testa.prototype.isSessionExpire = function isSessionExpire(data, a) {
    let userAgent = window.navigator.userAgent.toLowerCase(),
        ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
        window.webkit.messageHandlers.isSessionExpire.postMessage({ status: 'ready' });
    } else {
        IXFIAndroid.openReferalPage('session-expire');
    }
};

testa.prototype.openExternalUrl = function openExternalUrl(data, a) {
    let userAgent = window.navigator.userAgent.toLowerCase(),
        ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
        window.webkit.messageHandlers.openExternalUrl.postMessage({ status: 'ready', externalUrl: data });
    } else {
        IXFIAndroid.openReferalPage(data);
    }
};

testa.prototype.copyString = function copyString(data, a) {
    let userAgent = window.navigator.userAgent.toLowerCase(),
        ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
        window.webkit.messageHandlers.copyString.postMessage({ status: 'ready', copyString: data });
    } else {
        IXFIAndroid.openReferalPage('Copy:::' + data);
    }
};

testa.prototype.kycTears = function kycTears(data, a) {
    let userAgent = window.navigator.userAgent.toLowerCase(),
        ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
        window.webkit.messageHandlers.kycTears.postMessage({ status: 'ready' });
    } else {
        IXFIAndroid.openReferalPage('kycTears');
    }
};

testa.prototype.buyCryptoHistory = function buyCryptoHistory(data, a) {
    let userAgent = window.navigator.userAgent.toLowerCase(),
        ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
        window.webkit.messageHandlers.buyCryptoHistory.postMessage({ status: 'ready' });
    } else {
        IXFIAndroid.openReferalPage('buyCryptoHistory');
    }
};

testa.prototype.loadChart = function loadChart() {
    return document.getElementById('pairData');
};

var isMobileModule = new testa();

export { isMobileModule };
