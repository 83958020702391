import { TradeComponentId } from './trade.types';
import { AnimationOptions } from 'ngx-lottie';
import { MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

type TradeLottieName = 'NoAuth';
type TradeDialogName =
    | 'TemplateSelection'
    | 'TradePairSelection'
    | 'SpotTutorialSelection'
    | 'SaveConfirmationSelection';

/* Trade APIs used in the Application */
type TradeAPI =
    | 'GET_TEMPLATES'
    | 'CREATE_TEMPLATE'
    | 'UPDATE_TEMPLATE'
    | 'GET_CRYPTO_ASSETS'
    | 'PLACE_TRADE_ORDER'
    | 'CANCEL_TRADE_ORDER'
    | 'BINANCE_KLINE_DATA'
    | 'BINANCE_AGG_TRADES'
    | 'GET_TEMPLATE_DETAIL'
    | 'TRADE_PAIR_ORDER_BOOK'
    | 'BINANCE_AVG_PAIR_PRICE'
    | 'TRADE_PAIR_TICKER_DATA'
    | 'TRADE_PAIR_USER_HISTORY'
    | 'TRADE_PAIR_BUY_SELL_DATA'
    | 'BINANCE_TRADE_PAIR_TICKER'
    | 'TRADE_PAIR_MARKET_HISTORY'
    | 'TRADE_PAIR_CANDLESTICK_INFO'
    | 'BINANCE_TRADE_PAIR_ORDERBOOK'
    | 'IXFI_KLINE_DATA'
    | 'IXFI_AGG_TRADES'
    | 'IXFI_AVG_PAIR_PRICE'
    | 'IXFI_TRADE_PAIR_ORDERBOOK'
    | 'IXFI_TRADE_PAIR_TICKER';

/**
 * Maps components used in trade-pro to their unique Ids
 * These are marked `as const` to enable them to be readonly properties
 */
export const TRADE_COMPONENT_ID_MAP: { [key in TradeComponentId]: TradeComponentId } = {
    TradePairTicker: 'TradePairTicker',
    TradingView: 'TradingView',
    OrderBook: 'OrderBook',
    BuySell: 'BuySell',
    CryptoAssets: 'CryptoAssets',
    UserTradeHistory: 'UserTradeHistory',
    MarketTrades: 'MarketTrades'
} as const;

/**
 * Maps lottie used in trade-pro to their lottie-animation options
 * These are marked `as const` to enable them to be readonly properties
 */
export const TRADE_LOTTIE_MAP: { [key in TradeLottieName]: AnimationOptions } = {
    NoAuth: {
        path: 'assets/images/lottie/login-lottie.json'
    }
} as const;

/**
 * Maps mat dialogs to their respective dialog config
 * These are NOT marked `as const` as we don't want them to be readonly properties
 * so as to enable adding value to the `data` attribute dynamically
 */
export const TRADE_DIALOG_MAP: { [key in TradeDialogName]: MatDialogConfig } = {
    TemplateSelection: {
        hasBackdrop: true,
        direction: 'ltr',
        height: 'calc(100vh - 100px)',
        width: '75vw',
        panelClass: ['template-selection-dialog'],
        position: { bottom: '16px', left: '12vw' }
    },
    TradePairSelection: {
        hasBackdrop: true,
        direction: 'ltr',
        height: '100%',
        width: '400px',
        panelClass: ['coin-pair-dialog'],
        position: { bottom: '0px', left: '0px' }
    },
    SpotTutorialSelection: {
        hasBackdrop: true,
        direction: 'ltr',
        height: '66vh',
        width: '46vw',
        panelClass: 'tutorial-dialog',
        position: { top: '17vh', left: '27vw' }
    },
    SaveConfirmationSelection: {
        hasBackdrop: true,
        direction: 'ltr',
        height: '66vh',
        width: '46vw',
        panelClass: 'tutorial-dialog',
        position: { top: '17vh', left: '27vw' }
    }
};

/**
 * Maps all trade-pro API keys to their respective endpoints
 * These are marked `as const` to enable them to be readonly properties
 */
export const TRADE_API_MAP: { [endPoint in TradeAPI]: string } = {
    // CRUD: Templates
    GET_TEMPLATES: `${environment.IXFI_API}v1/user-trade-layout/layouts-metadata`,
    GET_TEMPLATE_DETAIL: `${environment.IXFI_API}v1/user-trade-layout/layout-details/`,
    CREATE_TEMPLATE: `${environment.IXFI_API}v1/user-trade-layout/create-layout`,
    UPDATE_TEMPLATE: `${environment.IXFI_API}v1/user-trade-layout/update-layout`,

    // Trade Pro APIs
    TRADE_PAIR_TICKER_DATA: `${environment.IXFI_TRADE_API_URL}market/ticker`,
    TRADE_PAIR_ORDER_BOOK: `${environment.IXFI_TRADE_API_URL}market/order-book`,
    TRADE_PAIR_CANDLESTICK_INFO: `${environment.IXFI_TRADE_API_URL}market/kline`,
    TRADE_PAIR_MARKET_HISTORY: `${environment.IXFI_TRADE_API_URL}market/trade-history`,

    // Ixfi APIs
    GET_CRYPTO_ASSETS: `${environment.IXFI_API}landing-page/all-coin-info`,

    // Trade Order APIs
    TRADE_PAIR_USER_HISTORY: `${environment.IXFI_API}v1/trade/all-orders`,
    TRADE_PAIR_BUY_SELL_DATA: `${environment.IXFI_API}api/public-api/coin-pair`,
    PLACE_TRADE_ORDER: `${environment.IXFI_API}v1/trade/place-order`,
    CANCEL_TRADE_ORDER: `${environment.IXFI_API}v1/trade/delete-order`,

    // Binance APIs
    BINANCE_KLINE_DATA: `${environment.BINANCE_API_URL}v3/uiKlines`,
    BINANCE_AGG_TRADES: `${environment.BINANCE_API_URL}v3/aggTrades`,
    BINANCE_AVG_PAIR_PRICE: `${environment.BINANCE_API_URL}v3/avgPrice`,
    BINANCE_TRADE_PAIR_ORDERBOOK: `${environment.BINANCE_API_URL}v3/depth`,
    BINANCE_TRADE_PAIR_TICKER: `${environment.BINANCE_API_URL}v3/ticker/24hr`,

    // IXFI trade api

    IXFI_KLINE_DATA: `${environment.IXFI_API_URL}v3/uiKlines`,
    IXFI_AGG_TRADES: `${environment.IXFI_API_URL}v3/aggTrades`,
    IXFI_AVG_PAIR_PRICE: `${environment.IXFI_API_URL}v3/avgPrice`,
    IXFI_TRADE_PAIR_ORDERBOOK: `${environment.IXFI_API_URL}v3/depth`,
    IXFI_TRADE_PAIR_TICKER: `${environment.IXFI_API_URL}v3/ticker/24hr`
} as const;
