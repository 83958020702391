import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from '../http/api.service';
import { Subscription } from 'rxjs';

export interface CountryList {
    country_pair: CountryObject[];
    current_location: CurrentLocation;
}

export interface CurrentLocation {
    country_code: string;
    flag_image: string;
    name: string;
    phonecode: string;
}

export interface CountryObject {
    id: string;
    is_operating: boolean;
    is_signup_with_phone_allowed?: boolean;
    name: string;
    phonecode: string;
    sortname: string;
    _id: string;
}

export const API_END_POINTS_COUNTRY = {
    COUNTRY_LIST: 'pages/country-list',
    STATE_LIST: 'v1/address/state-list',
    CITY_LIST: 'v1/address/city-list'
};

export const API_END_POINTS_GEO_DATA = {
    COUNTRY_LIST: 'geo-data/get-country-list',
    STATE_LIST: 'geo-data/get-state-list',
    CITY_LIST: 'geo-data/get-city-list',
    POSTAL_CODE: 'geo-data/get-postal-code-regex'
};

@Injectable({
    providedIn: 'root'
})
export class CountryService implements OnDestroy {
    staticCountries: CountryList = {} as CountryList;

    subscriptions: Subscription[] = [];
    constructor(private api: ApiService) {}

    getStaticCountriesAPI() {
        return this.api.get(API_END_POINTS_COUNTRY.COUNTRY_LIST);
    }

    getStatesOfCountry(country_id: string): any {
        return this.api.post(API_END_POINTS_COUNTRY.STATE_LIST, { country_id });
    }

    getCityOfState(state_id) {
        return this.api.post(API_END_POINTS_COUNTRY.CITY_LIST, { state_id });
    }

    public getStaticCountries() {
        return new Promise(fulfill => {
            if (Object.keys(this.staticCountries)?.length) {
                fulfill(this.staticCountries);
            } else {
                const sub = this.getStaticCountriesAPI().subscribe({
                    next: (res: { status: number; data: CountryList }) => {
                        if (res.status == 200) {
                            res.data.country_pair = res.data.country_pair.filter((a: any) => a.is_operating);
                            this.staticCountries = res.data;
                            fulfill(this.staticCountries);
                        } else {
                            fulfill(null);
                        }
                    },
                    error: () => {
                        fulfill(null);
                    }
                });
                this.subscriptions.push(sub);
            }
        });
    }

    public getStaticCountryData(): any {
        return this.getStaticCountries().then((countryData: CountryList | any) => {
            if (countryData) {
                const currentLocationData: CurrentLocation = countryData?.current_location || ({} as CurrentLocation);
                const countryList = countryData.country_pair || ([] as CountryObject[]);
                const defaultSelectedCountry =
                    currentLocationData && !currentLocationData.country_code
                        ? countryList[0]
                        : countryList.filter(country => country.sortname === currentLocationData.country_code)[0] || {};

                return { currentLocationData, countryList, defaultSelectedCountry };
            } else return {};
        });
    }

    getGEOCountriesAPI() {
        return this.api.get(API_END_POINTS_GEO_DATA.COUNTRY_LIST);
    }

    getGEOStatesAPI(country_id): any {
        return this.api.get(API_END_POINTS_GEO_DATA.STATE_LIST + `?country_code=${country_id}`);
    }

    getGEOCitiesAPI(country_id, state_id): any {
        return this.api.get(API_END_POINTS_GEO_DATA.CITY_LIST + `?country_code=${country_id}&state_code=${state_id}`);
    }

    getGEOPostalCodesAPI(country_id): any {
        return this.api.get(API_END_POINTS_GEO_DATA.POSTAL_CODE + `?country_code=${country_id}`);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
