import { CustomTemplatesService } from '../../services/custom-templates.service';
import { Component } from '@angular/core';

@Component({
    selector: 'app-custom-templates',
    templateUrl: './custom-templates.component.html',
    styleUrls: ['./custom-templates.component.scss']
})
export class CustomTemplatesComponent {
    constructor(public templatesService: CustomTemplatesService) { }
    isNumeric(value: any) {
        return !isNaN(value - parseFloat(value));
    }
}
