import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ErrorInfo } from '../models/error-info.model';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {
    private _errorSubject = new BehaviorSubject<ErrorInfo>({});
    private scaInvalidOtp = new Subject();
    constructor(private router: Router) {}

    get errorObs() {
        return this._errorSubject.asObservable();
    }

    updateErrorMessage(errorInfo: ErrorInfo) {
        this._errorSubject.next(errorInfo);
    }

    getInvalidOtpData() {
        return this.scaInvalidOtp.asObservable();
    }

    setInvalidOtpData(data) {
        this.scaInvalidOtp.next(data);
    }

    handleInvalidCoin() {
        const errorInfo: ErrorInfo = {
            errorCode: '404',
            errorTextOne: 'INVALID_COIN.HEADING',
            errorTextTwo: 'INVALID_COIN.MESSAGE'
        };
        this._errorSubject.next(errorInfo);
        this.router.navigate(['/page-not-found'], { skipLocationChange: true });
    }
}
