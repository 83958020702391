export const VALIDATION_PATTERNS = {
    NAME: `^(?!.*['_-]{2})(?!.*[!"#$%&()*+,.\/:;<=>?@\\[\\]\^{|}~]).+$`,
    USERNAME: '^[a-z0-9]{4,15}$',
    ADDRESS: `^(?!.*[!"'#$%&+:;<=>?@\\[\\]\^{|}~]).+$`,
    COUNTRY: `^(?!.*['"().-]{2})(?!.*[!#$%&*+,\/:;<=>?@\\[\\]\^_{|}~]).+$`,
    STATE: `^(?!.*[-~'().\/]{2})(?!.*[!"#$%&*+,:;<=>?@\\[\\]\^_{|}]).+$`,
    CITY: `^(?!.*[()'\/]{2})(?!.*[-!"#$%&*+,.:;<=>?@\\[\\]\^_{|}~]).+$`,
    POSTAL_CODE: `^(?!.*[-()\\[\\]]{2})(?!.*[!"'#$%&*+,.\/:;<=>?@\^_{|}~]).+$`,
    EMAIL: '^(?!.*[._-]{2})[a-z0-9_.+-]+@([a-z0-9_-]+.)+[a-z0-9_-]+$',
    OTP: '^[0-9]{6,6}$',
    MOBILE: '^[0-9]{6,15}$',
    PASSWORD: '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\\\\\\\\`~!-\\/@^_={}\\[\\]:;?.<>|])(?!.*\\s).{8,50}$',
    ANTI_PHISHING_CODE: '^[a-zA-Z0-9]{4,20}$',
    PREVENT_SPECIAL_CHAR: '^[a-zA-Z0-9]+$',
    PASSWORD_STRENGTH: /^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\\\\\\\\`~!-\\/@^_={}\\[\\]:;?.<>|])(?!.*\\s).{8,50}$/g,
    BACKUP_CODE: '^[a-z0-9A-Z]+$',
    NO_TRAILING_ZERO: /^[^\.]+?(?=\.0*$)|^[^\.]+?\..*?(?=0*$)|^[^\.]*$/g,
    REFERRAL: '^(?!.*[_-]{2})[a-z0-9_-]{4,20}$',
    IXFI_PIN: /^(?!.*(\d)\1{2})[0-9]{6}$/
};
