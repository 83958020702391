import { Component, Input, OnDestroy, inject } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../core';
import { API_RESPONSE_STATUS } from '../../../core/constants/enum.constants';
import { ToastMessageService } from '../../../shared/services/toast-message.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LottieModule } from 'ngx-lottie';
import { Subscription } from 'rxjs';

export enum POP_UP_TYPE {
    LOG_OUT = 1,
    SESSION_OUT = 2,
    LOGGED_OUT = 3,
    YES_NO = 4,
    GENERATE_BACKUP = 5,
    SUCCESS_NEWSLETTER = 6,
    ACCOUNT_DISABLE = 7,
    DISABLE_AUTH_SUCCESS = 8,
    RESET_SUCCESS = 9,
    RESET_CONFIRMATION = 10,
    BACKUP_CODE_LOGIN_PART_A = 11,
    BACKUP_CODE_LOGIN_PART_B = 12,
    TASK_THANKS = 13,
    AUTHENTICATOR_BACKUP_CODE = 14,
    TRUSTED_DEVICE_MAX_LIMIT = 15
}

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule, LottieModule]
})
export class ConfirmationModalComponent implements OnDestroy {
    @Input() data: { modalRef: NgbModalRef; type: POP_UP_TYPE };
    POP_UP_TYPE = POP_UP_TYPE;
    CDN_URL = environment.CDN_URL;
    private allowLogout = true;

    private auth = inject(AuthenticationService);
    private modal = inject(NgbModal);
    private toast = inject(ToastMessageService);
    subscription: Subscription;

    onLogout() {
        if (!this.allowLogout) {
            return;
        }
        this.allowLogout = false;
        this.subscription = this.auth.logout().subscribe(({ status, data, message }) => {
            if (status === API_RESPONSE_STATUS.success_code || status === API_RESPONSE_STATUS.authentication_expired) {
                this.toast.showSuccessToast({
                    toastMessage: message
                });
                this.data.modalRef.close({ value: true });
            }
            this.allowLogout = true;
        });
    }
    onOK() {
        this.data.modalRef.close({ value: true });
    }

    onCancel() {
        this.modal.dismissAll();
    }

    resetConfirmation(value) {
        this.data.modalRef.close({ value });
    }
    onCancelLogout() {
        this.data.modalRef.close(true);
    }

    onBackupCodeLogin(value) {
        this.data.modalRef.close({ value });
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
