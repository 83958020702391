import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    constructor(private cookie: CookieService) {}
    public getItem(key) {
        return this.cookie.get(key);
    }

    public setItem(key, value, time?, path?, domain?, secure?) {
        this.cookie.set(key, value, time, path, domain, secure);
    }
    public removeItem(key, path?, domain?, secure?) {
        this.cookie.delete(key, path, domain, secure);
    }
    public clear() {
        this.cookie.deleteAll();
    }
    public check(key) {
        return this.cookie.check(key);
    }

    public removeFromCookie(key, path) {
        if (this.cookie.check(key)) {
            this.cookie.delete(key, path);
        }
    }
}
