import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ToastBody } from '../models/toast-body.model';
import { ToastPopupComponent } from 'src/app/standalone/components/toast-popup/toast-popup.component';

enum ToastrEnum {
    SUCCESS_TIMEOUT = 3000,
    ERROR_TIMEOUT = 5000,
    WARNING_TIMEOUT = 5000
}
@Injectable({
    providedIn: 'root'
})
export class ToastMessageService {
    constructor(private toastr: ToastrService) {}

    // This function calls the success toast popup
    showSuccessToast(toastBody: ToastBody) {
        const activeToast = this.toastr.show(toastBody.toastMessage, toastBody.toastTitle, {
            toastClass: 'custom-toast',
            toastComponent: ToastPopupComponent,
            timeOut: ToastrEnum.SUCCESS_TIMEOUT
        });
        activeToast.toastRef.componentInstance.toastType = 'success';
    }

    // This function calls the error toast popup
    showErrorToast(toastBody: ToastBody) {
        const activeToast = this.toastr.show(toastBody.toastMessage, toastBody.toastTitle, {
            toastClass: 'custom-toast',
            toastComponent: ToastPopupComponent,
            timeOut: ToastrEnum.ERROR_TIMEOUT
        });
        activeToast.toastRef.componentInstance.toastType = 'error';
    }

    // This function calls the warning toast popup
    showWarningToast(toastBody: ToastBody) {
        const activeToast = this.toastr.show(toastBody.toastMessage, toastBody.toastTitle, {
            toastClass: 'custom-toast',
            toastComponent: ToastPopupComponent,
            timeOut: ToastrEnum.WARNING_TIMEOUT
        });
        activeToast.toastRef.componentInstance.toastType = 'warning';
    }
}
