import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DynamicScriptService {

    private scripts: { [key: string]: boolean } = {};

    constructor() { }

    loadScript(url: string, callback?: () => void): void {        
        if (this.scripts[url]) {
            return; // Script is already loaded
        }
        const script = document.createElement('script');
        script.src = url;
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.onload = () => {
            this.scripts[url] = true;
            if (callback) callback();
        }
        script.onerror = () => console.error(`Failed to load script: ${url}`);
        document.head.appendChild(script);
    }

    loadFingerprintScript() {
        const scriptUrl = environment.FINGERPRINT_JS;
        this.loadScript(scriptUrl, () => {
            this.initializeFingerprintJS(scriptUrl);
        });
    }

    async initializeFingerprintJS(scriptUrl) {
        // Dynamically import the module
        try {
            // Using dynamic import with a webpack ignore comment
            const module = await import(/* webpackIgnore: true */ scriptUrl);
            const FingerprintJS = module.default || module;

            const fp = await FingerprintJS.load({
                endpoint: environment.FINGERPRINT_JS_END_POINT
            });

        } catch (error) {
            console.error('Error importing the module:', error);
        }
    }
}
