import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { customSearchCountry } from 'src/app/core/utils';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-country-select',
    templateUrl: './country-select.component.html',
    standalone: true,
    imports: [CommonModule]
})
export class CountrySelectComponent implements OnInit {
    @Input() data;
    CDN = environment.CDN_URL;
    @ViewChild('countriesSearch') countriesSearch: ElementRef;
    searchTerm = '';
    countryList;

    ngOnInit(): void {
        this.countryList = this.data.countries;
    }

    customSearch(searchTerm: string) {
        this.searchTerm = searchTerm;
        if (searchTerm.trim() === '') {
            this.countryList = this.data.countries;
        } else {
            this.countryList = customSearchCountry(searchTerm, this.data.countries);
        }
    }
    resetCountries() {
        this.countriesSearch.nativeElement.value = '';
        this.customSearch('');
    }
    closeModal(status, selected) {
        this.data.modalRef.close({ status, selected });
    }

    selected(country) {
        this.closeModal(200, country);
    }
}
