<div class="modal-header border-0">
    <button type="button" class="btn-close" (click)="onCancel()"><em class="flaticon-close"></em></button>
</div>
<div class="modal-body text-center pt-0 update-available">
        <div class="icon-width-nav">
                <ng-lottie [options]="options" width="100%" hight="100%" class="d-flex justify-content-center mt-0">
                </ng-lottie>
        </div>
        <h5>Update Available</h5>
        <p>Upgrade your IXFI Experience Now!</p>
        <div>
            <button type="submit" (click)="onCancel()" class="btn-default updated-btn">
               Ok
            </button>
        </div>
</div>
