import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { STORAGE_KEY } from '../constants/enum.constants';
import { StorageService } from 'src/app/shared/services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class NoAuthGuard {
    constructor(private router: Router, private storageService: StorageService, private auth: AuthenticationService) {}
    canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
        const isSubDomain = JSON.parse(this.storageService.getItem(STORAGE_KEY.IS_SUBDOMAIN) || STORAGE_KEY.FALSE);
        if (isSubDomain) {
            if (route.queryParams?.isLogout) {
                this.auth.logout().subscribe();
                this.auth.clearAuthData();
            }
        }
        if (this.isLoggedIn()) {
            const reset_2fa_token = route.queryParamMap.get('token');
            if (reset_2fa_token) {
                this.auth.clearData();
                return true;
            }
            // navigate to landing page as user is already logged in
            this.router.navigate(['/landing']);
            return false;
        } else {
            return true;
        }
    }

    public isLoggedIn(): boolean {
        let status = false;
        const token = this.storageService.getItem(STORAGE_KEY.TOKEN) || '';
        const expiry: any = this.storageService.getItem(STORAGE_KEY.EXPIRE_TIME) || '';
        const refreshToken: any = this.storageService.getItem(STORAGE_KEY.REFRESH_TOKEN) || '';
        const isSubDomain = JSON.parse(this.storageService.getItem(STORAGE_KEY.IS_SUBDOMAIN) || STORAGE_KEY.FALSE);
        const returnUrl = this.storageService.getItem(STORAGE_KEY.RETURN_URL) || '';

        if (token && new Date(expiry * 1000) >= new Date()) {
            if (isSubDomain) {
                this.storageService.setItem(
                    STORAGE_KEY.IS_LOGGED_IN,
                    JSON.stringify(true),
                    undefined,
                    '/',
                    environment.DOMAIN,
                    true
                );
                this.storageService.setItem(STORAGE_KEY.TOKEN, token, undefined, '/', environment.DOMAIN, true);
                this.storageService.setItem(
                    STORAGE_KEY.TOKEN_EXPIRE_TIME,
                    expiry,
                    undefined,
                    '/',
                    environment.DOMAIN,
                    true
                );
                this.storageService.setItem(
                    STORAGE_KEY.REFRESH_TOKEN,
                    refreshToken,
                    undefined,
                    '/',
                    environment.DOMAIN,
                    true
                );
                this.storageService.removeItem(STORAGE_KEY.IS_SUBDOMAIN, '/', environment.DOMAIN, true);
                this.storageService.removeItem(STORAGE_KEY.RETURN_URL, '/', environment.DOMAIN, true);
                window.location.replace(decodeURI(returnUrl));
            }
            status = true;
        }
        return status;
    }
}
