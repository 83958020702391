import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../http';

@Injectable({
    providedIn: 'root'
})
export class FeeService {
    constructor(private api: ApiService, private http: HttpClient) {}

    getDepositFee() {
        return this.api.get(API_END_POINTS_FEES.COIN_INFO);
    }

    getTradingFee() {
        return this.api.get(API_END_POINTS_FEES.TRADE_FEES);
    }

    getSwapFee() {
        return this.api.get(API_END_POINTS_FEES.SWAP_FEES);
    }

    getFeeTable() {
        return this.http.get(`${environment.FEE_SERVICE}${API_END_POINTS_FEES.FEES_TABLE}`);
    }

    getActivePriceTable() {
        return this.http.get(`${environment.COIN_SERVICE}${API_END_POINTS_FEES.ACTIVE_COIN_PRICE}`);
    }

    getPriceTable() {
        return this.http.get(`${environment.COIN_SERVICE}${API_END_POINTS_FEES.COIN_PRICE}`);
    }
    getInfluxData(id){
        return this.http.get(`${environment.COIN_SERVICE}${API_END_POINTS_FEES.INFLUX_DATA}${id}`);
    }
}
export const API_END_POINTS_FEES = {
    COIN_INFO: 'lending-page/coin-info',
    TRADE_FEES: 'pages/get-trade-levels',
    SWAP_FEES: 'pages/get-swap-fees',
    FEES_TABLE: 'get-fee-table',
    ACTIVE_COIN_PRICE: 'coin-price/get-all-active-coin-price',
    COIN_PRICE: 'coin-price/get-all-coin-price',
    INFLUX_DATA:'coin-price/get-all-influxdb-data?id='
};
