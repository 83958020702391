import { IxfiThemeService } from 'header';
import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-four-not-four',
    templateUrl: './four-not-four.component.html',
    standalone: true,
    imports: [RouterLink, TranslateModule],
    styleUrls: ['./four-not-four.component.scss']
})
export class FourNotFourComponent {
    CDN_URL = environment.CDN_URL;

    constructor(private themeService: IxfiThemeService) {
        // init defaultTheme manually since header component is not used here
        this.themeService.setDefaultTheme();
    }
}
