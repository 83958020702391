import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/core';

@Injectable({
    providedIn: 'root'
})
export class WalletResolver {
    constructor(private userService: UserService) {}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {
        return this.userService.getModuleConfig();
    }
}
