<div class="modal-body">
    <ng-container *ngIf="isTrusted">
        <h5>{{ 'TRUSTED.TITLE' | translate }}</h5>
        <div class="why-need-section mt-4 pt-2">
            <div class="divide-left d-flex">
                <div class="divide-left-img">
                    <img [src]="CDN + 'profile/user-octagon.svg'" alt="user" />
                </div>
                <div class="device-detail-text text-start">
                    <h4>{{ 'TRUSTED.TEXT_1' | translate }}</h4>
                    <p>{{ 'TRUSTED.DESCRIPTION_1' | translate }}</p>
                </div>
            </div>

            <div class="divide-left d-flex">
                <div class="divide-left-img">
                    <img [src]="CDN + 'profile/user-octagon.svg'" alt="user" />
                </div>
                <div class="device-detail-text text-start">
                    <h4>{{ 'TRUSTED.TEXT_2' | translate }}</h4>
                    <p>{{ 'TRUSTED.DESCRIPTION_2' | translate }}</p>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-center form-area flex-column mt-5">
            <a class="btn btn-xxl btn-default" (click)="onCancel(true)">{{ 'TRUSTED.SETUP' | translate }}</a>
            <div class="line-text" (click)="onCancel(false)">
                <a>{{ 'TRUSTED.SKIP' | translate }}</a>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!isTrusted">
        <div class="security-center-card m-0">
            <div class="security-center-card-inner">
                <div class="verification-img d-flex justify-content-center">
                    <img [src]="CDN + 'profile/kyc-inprocess-gfx.svg'" alt="kyc-inprocess" />
                </div>

                <h5 class="wallet-kyc-title">{{ 'WALLET_KYC.TITLE' | translate }}</h5>
                <p class="device-detail-text text-start">{{ 'WALLET_KYC.TEXT' | translate }}</p>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-center form-area flex-column mt-5">
            <a class="btn btn-xxl btn-default" (click)="onCancel(true)">{{ 'WALLET_KYC.SETUP' | translate }}</a>
            <div class="line-text" (click)="onCancel(false)">
                <a>{{ 'WALLET_KYC.SKIP' | translate }}</a>
            </div>
        </div>
    </ng-container>
</div>
