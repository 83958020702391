<div class="card-fee">
    <section class="card-banner">
        <div class="card-graphic">
            <img class="frame-img" [src]="CDN_URL + 'exchange/card-fees/card-graphic.png'" alt="card-graphic" />
        </div>
    </section>
    <section>
        <div class="wrapper">
            <div class="card-info card-overlap">
                <h1>ixfi Card Fee information</h1>
                <div class="card-detail">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="fee-detail mb-3">
                                <div class="left-side">
                                    <img
                                        class="frame-img"
                                        [src]="CDN_URL + 'exchange/card-fees/account-provider.png'"
                                        alt="account-provider" />
                                </div>
                                <div class="right-side">
                                    <span> Name of the account Provider </span>
                                    <p>UAB Finansinės paslaugos “Contis“</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="fee-detail mb-3">
                                <div class="left-side">
                                    <img
                                        class="frame-img"
                                        [src]="CDN_URL + 'exchange/card-fees/account-name.png'"
                                        alt="account-name" />
                                </div>
                                <div class="right-side">
                                    <span> Account name </span>
                                    <p>ixfi Card Account</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="fee-detail mb-3">
                                <div class="left-side">
                                    <img class="frame-img" [src]="CDN_URL + 'exchange/card-fees/date.svg'" alt="date" />
                                </div>
                                <div class="right-side">
                                    <span>Date </span>
                                    <p>01 May  2024</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-text">
                        <ul>
                            <li>
                                This document informs you about the fees for using the main services linked to the
                                payment account. It will help you to compare these fees with those of other accounts
                            </li>
                            <li>
                                Fees may also apply for using services linked to the account which are not listed here.
                                Full information is available in your terms and conditions
                                <a [routerLink]="['/page/terms']">(Terms | ixfi)</a>.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="wrapper">
            <div class="card-info p-0">
                <table class="table table-borderless table-responsive">
                    <thead>
                        <tr>
                            <th class="table-title left-border">Service</th>
                            <th class="table-title right-border">Fee</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th colspan="2" class="table-sub-title" scope="row">General account service</th>
                        </tr>
                        <tr>
                            &nbsp;
                        </tr>
                        <tr>
                            <td>
                                <strong>maintaining the account</strong>
                                <ul>
                                    <li>Ixfi Card account</li>
                                </ul>
                            </td>
                            <td>
                                <div>&nbsp;</div>
                                <div class="d-flex justify-content-between price-list">
                                    <div>Monthly maintenance fee</div>
                                    <div>€0.00</div>
                                </div>
                                <div class="d-flex justify-content-between price-list">
                                    <strong class="total-amount">Total annual fee </strong>
                                    <strong class="total-amount">€0.00</strong>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <th colspan="2" class="table-sub-title" scope="row">Payments (excluding cards)</th>
                        </tr>
                        <tr>
                            &nbsp;
                        </tr>
                        <tr>
                            <td>
                                <strong>Sending and receiving money via SEPA</strong>
                                <ul>
                                    <li>Sending money in euros – SEPA</li>
                                    <li>Receipt of payments in euros – SEPA</li>
                                </ul>
                            </td>
                            <td>
                                <div>&nbsp;</div>

                                <div>Not applicable</div>
                                <div>Not applicable</div>
                            </td>
                        </tr>
                        <tr>
                            &nbsp;
                        </tr>
                        <tr>
                            <td>
                                <strong>Sending and receiving money outside of SEPA zone</strong>
                                <ul>
                                    <li>Sending money outside of SEPA zone</li>
                                    <li>Receipt of international payments</li>
                                </ul>
                            </td>
                            <td>
                                <div>&nbsp;</div>

                                <div>Not applicable</div>
                                <div>Not applicable</div>
                            </td>
                        </tr>
                        <tr>
                            &nbsp;
                        </tr>
                        <tr>
                            <th colspan="2" class="table-sub-title" scope="row">Cards and cash</th>
                        </tr>
                        <tr>
                            &nbsp;
                        </tr>
                        <tr>
                            <td>
                                <ul>
                                    <li>Issuing of debit card</li>
                                    <li>Issuing of additional debit card</li>
                                    <li>Replacement debit card</li>
                                    <li>Card cancellation</li>
                                </ul>
                            </td>
                            <td>
                                <div class="d-flex justify-content-between price-list">
                                    <div>Per card</div>
                                    <div>€10.00</div>
                                </div>
                                <div class="d-flex justify-content-between price-list">
                                    <div>Not applicable</div>
                                    <div>&nbsp;</div>
                                </div>
                                <div class="d-flex justify-content-between price-list">
                                    <div>Per card</div>
                                    <div>€20.00</div>
                                </div>
                                <div class="d-flex justify-content-between price-list">
                                    <div>Per card</div>
                                    <div>€0.00</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            &nbsp;
                        </tr>
                        <tr>
                            <td>
                                <ul>
                                    <li>Debit card payment in euros</li>
                                    <li>Debit card payment in a foreign currency</li>
                                </ul>
                            </td>
                            <td>
                                <div class="d-flex justify-content-between price-list">
                                    <div>Per transaction</div>
                                    <div>€0.00</div>
                                </div>
                                <div class="d-flex justify-content-between price-list">
                                    <div>Per transaction</div>
                                    <div>€0.00</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            &nbsp;
                        </tr>
                        <tr>
                            <td>
                                <ul>
                                    <li>Cash withdrawal in euros in Europe</li>
                                    <li>Cash withdrawal in a foreign currency outside Europe</li>
                                </ul>
                            </td>
                            <td>
                                <div class="d-flex justify-content-between price-list">
                                    <div>Per withdrawal</div>
                                    <div>€0.00</div>
                                </div>
                                <div class="d-flex justify-content-between price-list">
                                    <div>Per withdrawal</div>
                                    <div>€0.00</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            &nbsp;
                        </tr>
                        <tr>
                            <td>
                                <ul>
                                    <li>Refusing a payment due to lack of funds</li>
                                    <li>Allowing a payment despite lack of funds</li>
                                </ul>
                            </td>
                            <td>
                                <div class="d-flex justify-content-between price-list">
                                    <div>Per payment</div>
                                    <div>€0.00</div>
                                </div>
                                <div class="d-flex justify-content-between price-list">
                                    <div>Not applicable</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            &nbsp;
                        </tr>
                        <tr>
                            <th colspan="2" class="table-sub-title" scope="row">Overdrafts and related services</th>
                        </tr>
                        <tr>
                            &nbsp;
                        </tr>
                        <tr>
                            <td>
                                <ul>
                                    <li>Arranged overdraft</li>
                                    <li>Unarranged overdraft</li>
                                </ul>
                            </td>
                            <td>
                                <div class="d-flex justify-content-between price-list">
                                    <div>Not applicable</div>
                                </div>
                                <div class="d-flex justify-content-between price-list">
                                    <div>Not applicable</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            &nbsp;
                        </tr>
                        <tr>
                            <th colspan="2" class="table-sub-title" scope="row">Other services</th>
                        </tr>
                        <tr>
                            &nbsp;
                        </tr>
                        <tr>
                            <td>
                                <ul>
                                    <li>ATM balance enquiry</li>
                                </ul>
                            </td>
                            <td>
                                <div class="d-flex justify-content-between price-list">
                                    <div>Per enquiry</div>
                                    <div>€0.00</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            &nbsp;
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-info card-2 p-0">
                <table class="table table-borderless table-responsive">
                    <thead>
                        <tr>
                            <th class="table-title left-border card-2-space">Glossary of terms</th>
                            <th class="table-title right-border">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th class="table-sub-title" scope="row">Term</th>
                            <th class="table-sub-title" scope="row">Definition</th>
                        </tr>

                        <tr>
                            <td class="align-middle">
                                <div class="d-flex align-items-center flex-column flex-sm-row gap-3 my-2">
                                    <img
                                        class="frame-img"
                                        [src]="CDN_URL + 'exchange/card-fees/allowing-payments.svg'"
                                        alt="Allowing Payment" />
                                    <strong class="text-center text-sm-start">
                                        Allowing a payment despite lack of funds</strong
                                    >
                                </div>
                            </td>
                            <td class="align-middle">
                                <div class="my-2">
                                    The account provider allows a payment to be made from the customer’s account
                                    although there is not enough money in it (or it would take the customer past their
                                    arranged overdraft limit).
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle">
                                <div class="d-flex align-items-center flex-column flex-sm-row gap-3 my-2">
                                    <img
                                        class="frame-img"
                                        [src]="CDN_URL + 'exchange/card-fees/arranged-overdraft.svg'"
                                        alt="Arranged overdraft" />
                                    <strong class="text-center text-sm-start">Arranged overdraft</strong>
                                </div>
                            </td>
                            <td class="align-middle">
                                <div class="my-2">
                                    The account provider and the customer agree in advance that the customer may borrow
                                    money when there is no money left in the account. The agreement determines a maximum
                                    amount that can be borrowed, and whether fees and interest will be charged.
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle">
                                <div class="d-flex align-items-center flex-column flex-sm-row gap-3 my-2">
                                    <img
                                        class="frame-img"
                                        [src]="CDN_URL + 'exchange/card-fees/ATM-balance-enquiry.svg'"
                                        alt="ATM balance enquiry" />
                                    <strong class="text-center text-sm-start">ATM balance enquiry</strong>
                                </div>
                            </td>
                            <td class="align-middle">
                                <div class="my-2">The customer views the account balance at a cash machine.</div>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle">
                                <div class="d-flex align-items-center flex-column flex-sm-row gap-3 my-2">
                                    <img
                                        class="frame-img"
                                        [src]="CDN_URL + 'exchange/card-fees/Card-cancellation.svg'"
                                        alt="Card cancellation" />
                                    <strong class="text-center text-sm-start">Card cancellation </strong>
                                </div>
                            </td>
                            <td class="align-middle">
                                <div class="my-2">
                                    The account provider charges a cancellation fee if the customer cancels the card
                                    order within the first 14 days and a card has already been ordered in the customer’s
                                    name.
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle">
                                <div class="d-flex align-items-center flex-column flex-sm-row gap-3 my-2">
                                    <img
                                        class="frame-img"
                                        [src]="CDN_URL + 'exchange/card-fees/Cash-withdrawal.svg'"
                                        alt="Cash-withdrawal" />

                                    <strong class="text-center text-sm-start"
                                        >Cash withdrawal in euros in Europe</strong
                                    >
                                </div>
                            </td>
                            <td class="align-middle">
                                <div class="my-2">
                                    The customer takes cash out of the customer’s account in euros at a cash machine in
                                    Europe.
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle">
                                <div class="d-flex align-items-center flex-column flex-sm-row gap-3 my-2">
                                    <img
                                        class="frame-img"
                                        [src]="CDN_URL + 'exchange/card-fees/Cash-withdrawal-foreign.svg'"
                                        alt="Cash-withdrawal" />
                                    <strong class="text-center text-sm-start"
                                        >Cash withdrawal in a foreign currency outside Europe</strong
                                    >
                                </div>
                            </td>
                            <td class="align-middle">
                                <div class="my-2">
                                    The customer takes cash out of the customer’s account in foreign currency at a cash
                                    machine outside Europe.
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle">
                                <div class="d-flex align-items-center flex-column flex-sm-row gap-3 my-2">
                                    <img
                                        class="frame-img"
                                        [src]="CDN_URL + 'exchange/card-fees/Debit-card.svg'"
                                        alt="Debit card euros" />
                                    <strong class="text-center text-sm-start">Debit card payment in euros</strong>
                                </div>
                            </td>
                            <td class="align-middle">
                                <div class="my-2">
                                    The customer uses their debit card to make a payment in euros. This can be in a
                                    shop, online or over the phone.
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle">
                                <div class="d-flex align-items-center flex-column flex-sm-row gap-3 my-2">
                                    <img
                                        class="frame-img"
                                        [src]="CDN_URL + 'exchange/card-fees/Debit-card-payment.svg'"
                                        alt="Debit card" />
                                    <strong class="text-center text-sm-start"
                                        >Debit card payment in a foreign currency</strong
                                    >
                                </div>
                            </td>
                            <td class="align-middle">
                                <div class="my-2">
                                    The customer uses their debit card to make a payment in foreign currency. This can
                                    be in a shop, online or over the phone.
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle">
                                <div class="d-flex align-items-center flex-column flex-sm-row gap-3 my-2">
                                    <img
                                        class="frame-img"
                                        [src]="CDN_URL + 'exchange/card-fees/Digital-Card.svg'"
                                        alt="Digital card" />
                                    <strong class="text-center text-sm-start">Digital Card</strong>
                                </div>
                            </td>
                            <td class="align-middle">
                                <div class="my-2">Digital Card An online hosted representation of a physical card.</div>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle">
                                <div class="d-flex align-items-center flex-column flex-sm-row gap-3 my-2">
                                    <img
                                        class="frame-img"
                                        [src]="CDN_URL + 'exchange/card-fees/Issuing-debit-card.svg'"
                                        alt="debit card" />
                                    <strong class="text-center text-sm-start">Issuing of debit card</strong>
                                </div>
                            </td>
                            <td class="align-middle">
                                <div class="my-2">The account provider sends a debit card to the customer.</div>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle">
                                <div class="d-flex align-items-center flex-column flex-sm-row gap-3 my-2">
                                    <img
                                        class="frame-img"
                                        [src]="CDN_URL + 'exchange/card-fees/Issuing-card.svg'"
                                        alt="Issuing-card" />
                                    <strong class="text-center text-sm-start">Issuing of additional debit card</strong>
                                </div>
                            </td>
                            <td class="align-middle">
                                <div class="my-2">
                                    The account provider sends a debit card to the customer for an additional user.
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle">
                                <div class="d-flex align-items-center flex-column flex-sm-row gap-3 my-2">
                                    <img
                                        class="frame-img"
                                        [src]="CDN_URL + 'exchange/card-fees/Maintaining-account.svg'"
                                        alt="Maintaining-account" />
                                    <strong class="text-center text-sm-start">Maintaining the account</strong>
                                </div>
                            </td>
                            <td class="align-middle">
                                <div class="my-2">
                                    The account provider operates the account for use by the customer.
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle">
                                <div class="d-flex align-items-center flex-column flex-sm-row gap-3 my-2">
                                    <img
                                        class="frame-img"
                                        [src]="CDN_URL + 'exchange/card-fees/Receipt-international-payments.svg'"
                                        alt="Receipt-international-payments" />
                                    <strong class="text-center text-sm-start">Receipt of international payments</strong>
                                </div>
                            </td>
                            <td class="align-middle">
                                <div class="my-2">
                                    When money is sent to the customer’s account from an account not using a SEPA
                                    transfer.
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle">
                                <div class="d-flex align-items-center flex-column flex-sm-row gap-3 my-2">
                                    <img
                                        class="frame-img"
                                        [src]="CDN_URL + 'exchange/card-fees/Receipt-payments-SEPA.svg'"
                                        alt="Receipt-payments–SEPA" />
                                    <strong class="text-center text-sm-start"
                                        >Receipt of payments in euros – SEPA</strong
                                    >
                                </div>
                            </td>
                            <td class="align-middle">
                                <div class="my-2">
                                    When money is sent to the customer’s account from an account using SEPA.
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle">
                                <div class="d-flex align-items-center flex-column flex-sm-row gap-3 my-2">
                                    <img
                                        class="frame-img"
                                        [src]="CDN_URL + 'exchange/card-fees/Refusing-payment.svg'"
                                        alt="Refusing-payment" />
                                    <strong class="text-center text-sm-start"
                                        >Refusing a payment due to lack of funds</strong
                                    >
                                </div>
                            </td>
                            <td class="align-middle">
                                <div class="my-2">
                                    The account provider refuses a payment from the customer’s account because there is
                                    not enough money in it.
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle">
                                <div class="d-flex align-items-center flex-column flex-sm-row gap-3 my-2">
                                    <img
                                        class="frame-img"
                                        [src]="CDN_URL + 'exchange/card-fees/Replacement-debit-card.svg'"
                                        alt="Replacement-debit-card" />
                                    <strong class="text-center text-sm-start">Replacement debit card</strong>
                                </div>
                            </td>
                            <td class="align-middle">
                                <div class="my-2">
                                    Replacement debit card The account provider sends a replacement debit card to the
                                    customer.
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle">
                                <div class="d-flex align-items-center flex-column flex-sm-row gap-3 my-2">
                                    <img
                                        class="frame-img"
                                        [src]="CDN_URL + 'exchange/card-fees/Sending-money-euros-SEPA.svg'"
                                        alt="Sending money – SEPA" />
                                    <strong class="text-center text-sm-start">Sending money in euros – SEPA</strong>
                                </div>
                            </td>
                            <td class="align-middle">
                                <div class="my-2">
                                    The account provider transfers money, on the instruction of the customer, from the
                                    customer’s account to another account using SEPA.
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle">
                                <div class="d-flex align-items-center flex-column flex-sm-row gap-3 my-2">
                                    <img
                                        class="frame-img"
                                        [src]="CDN_URL + 'exchange/card-fees/Sending-money-outside-SEPA.svg'"
                                        alt="SSending money outside SEPA" />
                                    <strong class="text-center text-sm-start"
                                        >Sending money outside of SEPA zone</strong
                                    >
                                </div>
                            </td>
                            <td class="align-middle">
                                <div class="my-2">
                                    The account provider transfers money, on the instruction of the customer, from the
                                    customer’s account to another account outside of the SEPA zone.
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle">
                                <div class="d-flex align-items-center flex-column flex-sm-row gap-3 my-2">
                                    <img
                                        class="frame-img"
                                        [src]="CDN_URL + 'exchange/card-fees/Unarranged-overdraft.svg'"
                                        alt="SSending money outside SEPA" />
                                    <strong class="text-center text-sm-start">Unarranged overdraft</strong>
                                </div>
                            </td>
                            <td class="align-middle">
                                <div class="my-2">
                                    The customer borrows money when there is no money left in the account (or the
                                    customer has gone past their arranged overdraft limit) and this has not been agreed
                                    with the account provider in advance.
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</div>
