import { Injectable, OnDestroy, inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, map, tap } from 'rxjs';
import { ApiService } from '../http/api.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { KYC_STATUS } from 'src/app/profile-new/kyc-verification/kyc-verification.model';

@Injectable({
    providedIn: 'root'
})
export class UserService implements OnDestroy {
    public unreadCountMessage = new Subject<number>();
    public walletKYCVerified = false;
    userBasicData: any = {};
    kycTiersData: any = [];
    setAccessPageS: any = [];
    userCurrentKycTier: any = {};
    isLoggedIn: any;
    userSubject = new BehaviorSubject(null);
    profileStatusSubject = new BehaviorSubject(null);
    profileUrlSubject = new Subject();
    private authService = inject(AuthenticationService);

    public ixfiPinSet = new BehaviorSubject(false);
    public ixfiPinValid = new BehaviorSubject(false);
    profileStatus: any;
    public walletConfig: any;
    subscriptions: Subscription[] = [];

    constructor(private api: ApiService) {
        this.subscriptions.push(this.authService.loginStatus.subscribe(status => (this.isLoggedIn = status)));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    editProfile(data) {
        return this.api.post(USER_ROUTE.EDIT_PROFILE, data);
    }

    getUpdatedProfile() {
        return this.api.post(USER_ROUTE.GET_PROFILE);
    }

    uploadProfilePicture(file) {
        let formData = new FormData();
        formData.append('profile_pic', file);
        return this.api.post(USER_ROUTE.UPLOAD_PROFILE_PIC, formData);
    }

    changePassword(data) {
        return this.api.post(USER_ROUTE.CHANGE_PASSWORD, data);
    }

    changeUsername(data) {
        return this.api.post(USER_ROUTE.CHANGE_USERNAME, data);
    }

    addNewEmail(data) {
        return this.api.post(USER_ROUTE.ADD_EMAIL, data);
    }

    verifyNewEmail(data) {
        return this.api.post(USER_ROUTE.VERIFY_NEW_EMAIL, data);
    }

    deleteDeviceIp(id) {
        return this.api.delete(`${USER_ROUTE.DELETE_IP}${id}`);
    }

    setAntiPhishing2Fa(data) {
        return this.api.post(USER_ROUTE.SET_ANTI_PHISHING, data);
    }
    // get
    getSecurityNeed(data) {
        return this.api.get(USER_ROUTE.GET_SECURITY_METHOD, data);
    }
    getSecurityNeedV2(data) {
        return this.api.get(USER_ROUTE.GET_SECURITY_METHOD_V2, data);
    }
    // post send Security Email/SMS/AUTH
    sendSMSSecurity(data) {
        return this.api.post(USER_ROUTE.SEND_SMS, data);
    }

    sendOTPSecurity(data) {
        return this.api.post(USER_ROUTE.SEND_OTP, data);
    }

    sendOTPSecurityV2(data) {
        return this.api.post(USER_ROUTE.SEND_OTP_V2, data);
    }

    addPhoneNumber(data) {
        return this.api.post(USER_ROUTE.ADD_PHONE, data);
    }

    // enable
    sendSMSFinalSecurity(data) {
        return this.api.post(USER_ROUTE.VERIFY_PHONE, data);
    }

    // enable sms new
    enableSmsSecurity(data) {
        return this.api.post(USER_ROUTE.ENABLE_AUTH_SMS, data);
    }

    // disable sms auth
    disableSMSAuth(data) {
        return this.api.post(USER_ROUTE.DISABLE_AUTH_SMS, data);
    }

    // disable EMAIL auth
    disableEmailAuth(data) {
        return this.api.post(USER_ROUTE.DISABLE_AUTH_EMAIL, data);
    }

    enableEmailAuth(data) {
        return this.api.post(USER_ROUTE.ENABLE_AUTH_EMAIL, data);
    }

    changePromoEmailStatus(promoStatus) {
        return this.api.post(USER_ROUTE.PROMO_EMAIL, promoStatus);
    }

    updatePhoneNumber(phoneNumberData: any) {
        return this.api.post(USER_ROUTE.CHANGE_PHONE, phoneNumberData);
    }

    deleteUserProfileImage(data) {
        return this.api.post(USER_ROUTE.DELETE_PROFILE, data);
    }

    setUserBasicData(userObject) {
        this.userBasicData = userObject || {};
    }

    getUserBasicData() {
        return new Promise(fulfill => {
            let isLoggedIn = this.authService.isLoggedIn();

            if (isLoggedIn) {
                if (Object.keys(this.userBasicData)?.length) fulfill(this.userBasicData);
                else {
                    const profileSub = this.getUpdatedProfile().subscribe({
                        next: (res: any) => {
                            if (res.status == 200) {
                                this.setUserBasicData(res.data);
                                fulfill(res.data);
                            } else {
                                this.setUserBasicData({});
                                fulfill(null);
                            }
                        },
                        error: () => {
                            this.setUserBasicData({});
                            fulfill(null);
                        }
                    });
                    this.subscriptions.push(profileSub);
                }
            } else {
                this.setUserBasicData({});
                fulfill(null);
            }
        });
    }

    ping() {
        return this.api.get(USER_ROUTE.PING);
    }

    getMyLoginHistory() {
        return this.api.post(USER_ROUTE.LOGIN_HISTORY);
    }

    getIntercomUserDetail(): Observable<{
        data: { intercomId: string; intercomHash: string };
        status: number;
        message: string;
    }> {
        return this.api.post(USER_ROUTE.INTERCOM_ID, { device_type: 'web' });
    }

    contactUsUserDetail(data) {
        return this.api.post(this.isLoggedIn ? USER_ROUTE.INTERCOM_LOGGED_IN : USER_ROUTE.INTERCOM_LOGGED_OUT, data);
    }

    submitUserProfileRequest(formData) {
        return this.api.post(USER_ROUTE.SUBMIT_USER_REQUEST, formData);
    }

    /**
     *update preference
     */
    updatePreference(data) {
        return this.api.put(USER_ROUTE.UPDATE_PREFERENCE, data);
    }

    updateUsername(data) {
        return this.api.post(USER_ROUTE.UPDATE_USERNAME, data);
    }

    public ixfiPin(url, data) {
        return this.api.post(url, data);
    }

    public checkTrustedDevice() {
        return this.api.get(USER_ROUTE.IS_TRUSTED_WEB);
    }

    getProfileStatus() {
        return this.api.get(USER_ROUTE.GET_PROFILE_STATUS).pipe(
            map(response => {
                if (response.status === 200) this.profileStatusSubject.next(response.data);
                this.profileStatus = response.data;
                this.walletKYCVerified =
                    this.profileStatus?.wallet_kyc_data?.kyc_verification_status === KYC_STATUS.VERIFIED;

                return this.profileStatus;
            })
        );
    }

    getProfileStatusLocally(): Observable<any> {
        if (this.profileStatus) {
            return this.profileStatusSubject.asObservable();
        }
        return this.getProfileStatus();
    }

    setProfileStatusLocally(data): any {
        this.profileStatus = data;
        this.profileStatusSubject.next(data);
    }
    trackDepositWithdraw() {
        return this.api.get(USER_ROUTE.TRACK_ACK);
    }
    getModuleConfig() {
        return this.api.get(USER_ROUTE.CONFIG).pipe(tap(response => (this.walletConfig = response.data)));
    }
    getModuleConfigData() {
        return this.api.get(USER_ROUTE.CONFIG);
    }
}

export const USER_ROUTE = {
    EDIT_PROFILE: 'v1/users/edit-profile',
    GET_PROFILE: 'v1/users/updated-profile',
    UPLOAD_PROFILE_PIC: 'v1/users/upload-profile-pic',
    CHANGE_PASSWORD: 'v1/users/change-password',
    CHANGE_USERNAME: 'v1/users/update-username',
    ADD_EMAIL: 'v1/users/add-email',
    VERIFY_NEW_EMAIL: 'v1/users/verify-new-email',
    DELETE_IP: 'v1/users/delete-whitelisted-device/',
    SET_ANTI_PHISHING: 'v1/users/setup-anti-phishing-code',
    GET_SECURITY_METHOD: 'v1/users/required-security-methods',
    SEND_SMS: 'v1/users/send-number-verification-sms',
    SEND_OTP: 'v1/users/send-OTP-security-methods',
    ADD_PHONE: 'v1/users/add-phone',
    VERIFY_PHONE: 'v1/users/verify-phone-number',
    ENABLE_AUTH_SMS: 'v1/users/enable-sms-auth',
    DISABLE_AUTH_SMS: 'v1/users/disable-sms-auth',
    CHANGE_PHONE: 'v1/users/change-mobile-no',
    PROMO_EMAIL: 'v1/users/update-promo-mails-settings',
    DELETE_PROFILE: 'v1/users/delete-profile-pic',
    KYC_PERMISSION: 'v1/kyc-tier/permission-list',
    PING: 'v1/users/ping',
    SECURITY_QUESTION: 'v1/users/security-question',
    SECURITY_QA: 'v1/users/security-qa-user',
    LOGIN_HISTORY: 'v1/users/login-history',
    GET_BALANCE: 'v1/wallet/get-wallet-doller-cost',
    GET_POINTS: 'v1/reward-points/get-total-points',
    GET_PROFILE_STATUS: 'v1/users/user-profile-status',
    SUBMIT_USER_REQUEST: 'v1/user-request/submit',
    GET_SECURITY_METHOD_V2: 'v1/users/required-security-methods-v2',
    SEND_OTP_V2: 'v1/users/send-OTP-security-methods-v2',
    DISABLE_AUTH_EMAIL: 'v1/users/disable-email-auth',
    ENABLE_AUTH_EMAIL: 'v1/users/enable-email-auth',
    UPDATE_PREFERENCE: 'v1/users/update-preference',
    UPDATE_USERNAME: 'v1/users/update-username',

    // acknowledge
    TRACK_ACK: 'v1/users/un-ack-tracks',

    // module Config
    CONFIG: 'v1/users/get-users-configurations',
    // check trusted device
    IS_TRUSTED_WEB: 'v1/sca-flow/trusted-device/is-trusted-device?is_for_card=false',
    //intercom
    INTERCOM_LOGGED_IN: 'v1/ticket/submit-intercom-form',
    INTERCOM_LOGGED_OUT: 'misc/submit-intercom-form',
    INTERCOM_ID: 'v1/users/get-intercom-id'
};
