import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, from, map, shareReplay, switchMap } from 'rxjs';
import { ApiService, AuthenticationService } from '../../core';
import { BinanceApiService } from '../../core/services/binance-api.service';
import { WebsocketService } from '../../core/services/websocket.service';
import { StorageService } from '../../shared/services/storage.service';
import { BuySellValidation, TradePairBuySellData } from 'src/app/trade-pro/models/trade-pair-history.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TRADE_API_MAP } from 'src/app/trade-pro/constants/trade.constants';
import { UserTradePairHistoryResponse } from 'src/app/trade-pro/models/trade-order-history.model';
import { BinanceTradeOrder, BinanceOrderbook } from '../constants/trade.model';
import { MarketPair } from 'src/app/core/models/market-pair.model';
import { WalletService } from 'src/app/wallet-v2/shared/service/wallet.service';
import { environment } from 'src/environments/environment';

interface BinanceTradePairTickerHttpResponse {
    symbol: string; // Symbol Name
    openPrice: string; // Opening price of the Interval
    highPrice: string; // Highest price in the interval
    lowPrice: string; // Lowest  price in the interval
    lastPrice: string; // Closing price of the interval
    volume: string; // Total trade volume (in base asset)
    quoteVolume: string; // Total trade volume (in quote asset)
    openTime: number; // Start of the ticker interval
    closeTime: number; // End of the ticker interval

    firstId: number; // First tradeId considered
    lastId: number; // Last tradeId considered
    count: number; // Total trade count

    priceChange: string;
    priceChangePercent: string;
    weightedAvgPrice: string;
    prevClosePrice: string;
    lastQty: string;
    bidPrice: string;
    bidQty: string;
    askPrice: string;
    askQty: string;
}

@Injectable({
    providedIn: 'root'
})
export class TradeService {
    isBinanceApiFails = false;

    private activeTradePairSubject = new BehaviorSubject<MarketPair | null>(null);
    activeTradePairObs = this.activeTradePairSubject.asObservable();
    updateActiveTradePair(newTradePairInfo: MarketPair) {
        this.activeTradePairSubject.next(newTradePairInfo);
    }

    private http = inject(HttpClient);

    constructor(
        public market: BinanceApiService,
        public wallet: WalletService,
        public socket: WebsocketService,
        public storage: StorageService,
        public auth: AuthenticationService,
        private api: ApiService
    ) {}

    placeTradeOrder(data) {
        return this.api.post('v1/trade/place-order', data);
    }

    getAllOrders(data) {
        return this.api.post('v1/trade/all-orders', data);
    }

    getSingleMarketPair(pair_name, isLoggedIn) {
        if (!!isLoggedIn) {
            return this.api.post('v1/coin/coin-pair', { pair_name: pair_name });
        } else {
            return this.api.get(`api/public-api/coin-pair?pair_name=${pair_name}`);
        }
    }
    /**
     * HTTP call to fetch the buy-sell validation data for the trade pair
     * @param pairName Trade Pair Name
     * @returns Trade Pair's Buy Sell Data
     */
    fetchTradePairBuySellData(pairName: string): Observable<TradePairBuySellData> {
        let params = new HttpParams();
        params = params.append('pair_name', pairName);
        return this.http
            .get<{ message: string; status: number; data: TradePairBuySellData[] }>(
                TRADE_API_MAP.TRADE_PAIR_BUY_SELL_DATA,
                {
                    params: params
                }
            )
            .pipe(
                map(response => {
                    let tradePairBuySellData: TradePairBuySellData[] = [];
                    if (response.status === 200) tradePairBuySellData = response.data;
                    return tradePairBuySellData[0];
                })
            );
    }

    /**
     * HTTP call to fetch all Pending / Cancelled / Executed Trade orders
     * @param payload baseCoinId and quoteCoinId
     * @returns Pending/Executed/Cancelled trade Orders of the Trade Pair
     */
    fetchUserTradePairHistory(payload: {
        primary_currency_id: any;
        secondary_currency_id: any;
    }): Observable<UserTradePairHistoryResponse> {
        return this.http
            .post<{
                status: number;
                message: string;
                data: UserTradePairHistoryResponse;
            }>(TRADE_API_MAP.TRADE_PAIR_USER_HISTORY, payload)
            .pipe(
                map(response => {
                    const userTradeHistory: UserTradePairHistoryResponse = {
                        pending_orders: [],
                        canceled_orders: [],
                        trade_history: [],
                        order_history: []
                    };
                    if (response.status === 200) {
                        userTradeHistory.canceled_orders = response.data.canceled_orders;
                        userTradeHistory.order_history = response.data.order_history;
                        userTradeHistory.pending_orders = response.data.pending_orders;
                        userTradeHistory.trade_history = response.data.trade_history;
                    }
                    return userTradeHistory;
                })
            );
    }

    mapUserTradePairHistoryResponse(response: UserTradePairHistoryResponse): UserTradePairHistoryResponse {
        const userTradeHistory: UserTradePairHistoryResponse = {
            pending_orders: [],
            canceled_orders: [],
            trade_history: [],
            order_history: []
        };
        userTradeHistory.canceled_orders = response.canceled_orders;
        userTradeHistory.order_history = response.order_history;
        userTradeHistory.pending_orders = response.pending_orders;
        userTradeHistory.trade_history = response.trade_history;
        return userTradeHistory;
    }

    fetchAggTradesFromBinance(pairName: string): Observable<BinanceTradeOrder[]> {
        const url: URL = new URL(
            this.isBinanceApiFails ? TRADE_API_MAP.IXFI_AGG_TRADES : TRADE_API_MAP.BINANCE_AGG_TRADES
        );
        url.searchParams.append('symbol', pairName);
        url.searchParams.append('limit', '40');
        let headers;
        if (this.isBinanceApiFails) headers = { 'x-api-key': '3e3ekfkssjnjsdnj4444cdscnkj443' };
        return from(fetch(url, { method: 'GET', mode: 'cors', cache: 'no-cache', headers: headers })).pipe(
            switchMap(response => {
                return response.json();
            })
        );
    }

    fetchTickerDataFromBinance(pairName: string): Observable<BinanceTradePairTickerHttpResponse> {
        const url: URL = new URL(
            this.isBinanceApiFails ? TRADE_API_MAP.BINANCE_TRADE_PAIR_TICKER : TRADE_API_MAP.BINANCE_TRADE_PAIR_TICKER
        );
        let headers;
        if (this.isBinanceApiFails) headers = { 'x-api-key': '3e3ekfkssjnjsdnj4444cdscnkj443' };
        url.searchParams.append('symbol', pairName);

        return from(fetch(url, { method: 'GET', mode: 'cors', cache: 'no-cache', headers: headers })).pipe(
            switchMap(response => {
                return response.json();
            }),
            shareReplay(1)
        );
    }

    fetchOrderbookFromBinance(pairName: string): Observable<BinanceOrderbook> {
        const url: URL = new URL(
            this.isBinanceApiFails
                ? TRADE_API_MAP.IXFI_TRADE_PAIR_ORDERBOOK
                : TRADE_API_MAP.BINANCE_TRADE_PAIR_ORDERBOOK
        );
        url.searchParams.append('symbol', pairName);
        url.searchParams.append('limit', '25');
        let headers;
        if (this.isBinanceApiFails) headers = { 'x-api-key': '3e3ekfkssjnjsdnj4444cdscnkj443' };
        return from(fetch(url, { method: 'GET', mode: 'cors', cache: 'no-cache', headers: headers }))
            .pipe(
                switchMap(response => {
                    return response.json();
                })
            )
            .pipe(
                map((response: any) => {
                    const orderbook: BinanceOrderbook = {
                        bids: response.bids.map(b => [Number(b[0]), Number(b[1])]),
                        asks: response.asks.map(a => [Number(a[0]), Number(a[1])]),
                        lastUpdateId: response.lastUpdateId
                    };
                    return orderbook;
                })
            );
    }

    fetchAvgPairPriceFromBinance(pairName: string): Observable<{ price: string; mins: number }> {
        const url: URL = new URL(
            this.isBinanceApiFails ? TRADE_API_MAP.IXFI_AVG_PAIR_PRICE : TRADE_API_MAP.BINANCE_AVG_PAIR_PRICE
        );
        url.searchParams.append('symbol', pairName);
        let headers;
        if (this.isBinanceApiFails) headers = { 'x-api-key': '3e3ekfkssjnjsdnj4444cdscnkj443' };
        return from(fetch(url, { method: 'GET', mode: 'cors', cache: 'no-cache', headers: headers })).pipe(
            switchMap(response => {
                return response.json();
            })
        );
    }

    /**
     * HTTP call to cancel trade order
     * @param payload Id and the `side` of the order which is to be cancelled
     * @returns
     */
    cancelTradeOrder(payload: { order_id: string; side: 0 | 1 }): Observable<{ message: string; status: number }> {
        return this.http.post<{ message: string; status: number }>(TRADE_API_MAP.CANCEL_TRADE_ORDER, payload);
    }

    /**
     * HTTP call to place trade order
     * @param payload
     * @returns
     */
    // placeTradeOrder(payload: TradeOrderPayload): Observable<{ message: string; status: number }> {
    //     return this.http.post<{ message: string; status: number }>(TRADE_API_MAP.PLACE_TRADE_ORDER, payload);
    // }

    initAndGetBuySellValidations(response: TradePairBuySellData['filters']): BuySellValidation {
        const buySellValidation: BuySellValidation = <BuySellValidation>{};
        response.forEach((item: any) => {
            if (
                [
                    'ICEBERG_PARTS',
                    'LOT_SIZE',
                    'MARKET_LOT_SIZE',
                    'MAX_NUM_ALGO_ORDERS',
                    'MAX_NUM_ORDERS',
                    'NOTIONAL',
                    'MIN_NOTIONAL',
                    'PERCENT_PRICE_BY_SIDE',
                    'PRICE_FILTER',
                    'TRAILING_DELTA'
                ].includes(item.filterType)
            )
                buySellValidation[item.filterType] = item;
        });
        return buySellValidation;
    }

    checkApiFails(): Observable<any> {
        return this.http.get(environment.BINANCE_API_FAILS_URL);
    }
}
