import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TradeWidgetsCacheService } from './advanced-trade/trade-widgets-cache.service';

const TRADE_API_CACHE_LIST: string[] = [
    'market/ticker',
    'market/trade-history',
    'market/order-book',
    'v1/wallet/get-wallets-v2'
];
@Injectable()
export class TradeCacheInterceptor implements HttpInterceptor {
    constructor(private service: TradeWidgetsCacheService) {}
    isDataCached(request: HttpRequest<unknown>): { response: any; isCached: boolean } {
        let url = request.url;
        let params = request.params.get('pair_name');
        if (!params) {
            if (url.includes('wallet/get-wallets-v2')) {
                return { response: this.service.getKeyValue('wallets-v2'), isCached: true };
            } else {
                return { response: null, isCached: false };
            }
        } else {
            if (url.includes('market/ticker')) {
                return { response: this.service.getKeyValue('ticker-' + params), isCached: true };
            } else if (url.includes('market/trade-history')) {
                return {
                    response: this.service.getKeyValue('tradeHistory-' + params),
                    isCached: true
                };
            } else if (url.includes('market/order-book')) {
                return { response: this.service.getKeyValue('orderBook-' + params), isCached: true };
            } else {
                return { response: null, isCached: false };
            }
        }
    }
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
        let forwardedRequest = request;
        /**
         * Check If Request URL belongs in the API_CACHE_LIST
         */
        const endPoint = request.url.split(environment.IXFI_API)[1];
        if (TRADE_API_CACHE_LIST.includes(endPoint)) {
            let cacheObj = this.isDataCached(request);

            if (cacheObj.isCached) {
                /**
                 * Check If API is stored in Storage Memory
                 */
                let dataFromStorage = cacheObj.response;
                if (dataFromStorage) {
                    const cachedResponse = new HttpResponse({
                        body: dataFromStorage,
                        url: request.url
                    });
                    return of(cachedResponse);
                }
            }
        }
        return next.handle(forwardedRequest);
    }
}
