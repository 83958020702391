export enum KYC_STATUS {
    CREATED = 'created', // new session is created
    STARTED = 'started', // user has started the session
    ABANDONED = 'abandoned', // user has abandoned/not completed the session
    EXPIRED = 'expired', // user has not completed the session, and it got expired after 7 days
    SUBMITTED = 'submitted', // user has submitted docs for the session
    VERIFIED = 'verified', // the user's kyc got approved
    RESUBMISSION_REQUESTED = 'resubmission_requested', // docs need to be resubmitted because of any specific reason
    REJECTED = 'rejected', // the user's kyc got rejected
    PENDING = 'pending', // not in used for veriff but considering this for old kyc (from ondato)
    ADMIN_REQUEST_PENDING = 'request_pending_from_admin', //request is pending from admin end
    ADMIN_REQUEST_APPROVED = 'admin_request_approved', //request is approved from admin end
    ADMIN_REQUEST_REJECTED = 'admin_request_rejected', //request is rejected from admin end
    PERMANENTLY_REJECTED = 'admin_request_rejected_permanently',
    DISABLED = 'disabled'
}

export interface KYCData {
    fullname: string;
    id: string;
    is_kyc_started: boolean;
    is_kyc_verified: boolean;
    kyc_rejected_reason: boolean;
    canRetry: boolean;
    kyc_type: string;
    kyc_verification_status: KYC_STATUS;
    ondato_identification_id?: string; // need to remove
    ondato_identification_url?: string; // need to remove
    tier: KYC_TIER;
}

export enum KYC_TIER {
    TIER_0 = 0,
    TIER_1 = 1,
    TIER_2 = 2,
    TIER_3 = 3
}
// new KYC status response
export interface KYC_STATUS_RESPONSE {
    citizenship: string;
    date_of_birth: string;
    full_name: string;
    gender: string;
    kyc_type: string;
    kyc_verification_status: KYC_STATUS;
    nationality: string;
    verification_type: VERIFICATION_TYPE;
    _id: string;
}

export enum VERIFICATION_TYPE {
    CHANGE_KYC = 'change_kyc',
    NEW_KYC = 'new_kyc',
    RENEW_KYC = 'renew_kyc',
    ADD_WALLET_KYC = 'add_wallet_kyc'
}
