import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'precision',
    standalone: true
})
export class PrecisionPipe implements PipeTransform {
    transform(value: number, fractionSize: number) {
        if (value) {
            return parseFloat(parseFloat(value.toString()).toFixed(fractionSize)) == 0
                ? 0
                : parseFloat(value.toString()).toFixed(fractionSize);
        } else {
            return value;
        }
    }
}
