<!-- Deposit & with fees columns  -->
<!-- Coin/Token  -->
<ng-template #depositFeeCoinToken let-zoneCoin="element">
    <div class="d-flex align-items-center">
        <span class="single-coin m-1">
            <picture>
                <source [srcset]="CDN + zoneCoin?.coin_icon">
                <img [src]="CDN + zoneCoin?.coin_icon" [alt]="zoneCoin?.coin_code" />
            </picture>
        </span>
        <span>{{ zoneCoin?.coin_code | uppercase }}</span>
    </div>
</ng-template>
{{ depositFeeCoinToken | registerTemplate : 'depositFeeCoinToken' }}

<!-- Full Name -->
<ng-template #depositFeeFullName let-zoneCoin="element">
    <span data-toggle="tooltip" data-placement="auto" tooltip="{{ zoneCoin?.coin_name }}">
        {{ zoneCoin?.coin_name.length > 25 ? (zoneCoin?.coin_name | slice : 0 : 25) + '..' : zoneCoin?.coin_name }}
    </span>
</ng-template>
{{ depositFeeFullName | registerTemplate : 'depositFeeFullName' }}

<!-- Network -->
<ng-template #depositFeeNetwork let-zoneCoin="element">
    <div *ngFor="let network of zoneCoin.coin_networks">
        {{ network?.network_name }}
    </div>
</ng-template>
{{ depositFeeNetwork | registerTemplate : 'depositFeeNetwork' }}

<!-- DepositFee -->
<ng-template #depositFeeDeposit let-zoneCoin="element">
    {{ 'FREE' }}
</ng-template>
{{ depositFeeDeposit | registerTemplate : 'depositFeeDeposit' }}

<!-- Network Fee -->
<ng-template #depositNetworkFee let-zoneCoin="element">
    <div>
        {{ zoneCoin?.coin_networks[0]?.withdraw_fee }}
    </div>
</ng-template>
{{ depositNetworkFee | registerTemplate : 'depositNetworkFee' }}

<!-- ------------------------ -->
<!-- Spot Trading Columns -->
<!-- ------------------------ -->

<!-- Level -->
<ng-template #spotTradingLevel let-zoneCoin="element">
    {{ 'VIP ' + zoneCoin.level }}
</ng-template>
{{ spotTradingLevel | registerTemplate : 'spotTradingLevel' }}

<!-- 30 Day trade Volume -->
<ng-template #spotTrading30Day let-zoneCoin="element">
    {{
        zoneCoin?.minimum_btc_required ? '≥ ' + zoneCoin?.minimum_btc_required : '< ' + zoneCoin?.minimum_btc_next_level
    }}
    BTC
</ng-template>
{{ spotTrading30Day | registerTemplate : 'spotTrading30Day' }}

<!-- Maker/Taker -->
<ng-template #spotTradingMakerTaker let-zoneCoin="element">
    {{ zoneCoin.maker_commission_perc }} % / {{ zoneCoin.taker_commission_perc }} %
</ng-template>
{{ spotTradingMakerTaker | registerTemplate : 'spotTradingMakerTaker' }}

<!-- ------------------------ -->
<!-- Liquidity Swap fee Columns  -->
<!-- ------------------------ -->

<!-- Level -->
<ng-template #liquidityLevel let-zoneCoin="element">
    <span class="pl-4">
        {{ 'VIP ' + zoneCoin.index }}
    </span>
</ng-template>
{{ liquidityLevel | registerTemplate : 'liquidityLevel' }}

<!-- Pool -->
<ng-template #liquidityPool let-zoneCoin="element">
    <div class="d-flex align-items-center">
        <span class="multi-coin">
            <picture>
                <source [srcset]="previousSelectedPair?.base_coin_id.coin_icon_url">
                <img
                *ngIf="previousSelectedPair?.base_coin_id"
                height="15"
                alt="base"
                [src]="previousSelectedPair?.base_coin_id.coin_icon_url" />
            </picture>
            <picture>
                <source [srcset]="previousSelectedPair?.quote_coin_id.coin_icon_url">
                <img
                *ngIf="previousSelectedPair?.quote_coin_id"
                height="15"
                alt="quote"
                [src]="previousSelectedPair?.quote_coin_id.coin_icon_url" />
            </picture>
            
           
        </span>
        <span class="ml-2 text-dark">
            {{ previousSelectedPair?.base_symbol }} / {{ previousSelectedPair?.quote_symbol }}</span
        >
    </div>
</ng-template>
{{ liquidityPool | registerTemplate : 'liquidityPool' }}

<!-- Fee -->
<ng-template #liquidityFee let-zoneCoin="element">
    {{ zoneCoin.level }}
</ng-template>
{{ liquidityFee | registerTemplate : 'liquidityFee' }}

<!-- ------------------------ -->
<!-- Column Templates END HERE  -->
<!-- ------------------------ -->
<section [ngClass]="{ 'mt-header': !isMobile }" id="fees-table">
    <div class="container pt-4 mb-5">
        <div class="d-md-flex flex-row">
            <aside class="vertical-tab-menu">
                <div
                    class="nav flex-column nav-pills w-100"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical">
                    <h4 class="sub-title" *ngIf="!isMobile">
                        <span> {{ 'FEE_STRUCTURE.TITLE' | translate }}</span>
                    </h4>

                    <a class="active" data-bs-toggle="pill" href="#personalDetails" (click)="page = 1">
                        <img [src]="CDN + 'exchange/icon/deposit.svg'" alt="deposit" />
                        <span class="left-menu-text">
                            {{ 'FEE_STRUCTURE.DEPOSIT_WITHDRAWAL_FEE' | translate }}
                        </span>
                    </a>

                    <a data-bs-toggle="pill" href="#accountSecurity" (click)="page = 1">
                        <img [src]="CDN + 'exchange/icon/spot.svg'" alt="Spot" />
                        <span class="left-menu-text">{{ 'FEE_STRUCTURE.TRADING.SPOT_TRADING' | translate }}</span>
                    </a>
                </div>
            </aside>

            <!-- deposit and withdrawal fee tab -->
            <!-- skeletonBox loader  -->
            <ng-template #skeletonBox>
                <div class="table-skeleton-wrapper">
                    <div class="table-outer-skeleton" *ngFor="let i of [].constructor(15)">
                        <ngx-skeleton-loader [count]="1" [animation]="'progress'"> </ngx-skeleton-loader>
                    </div>
                </div>
            </ng-template>

            <!-- Expand row ref  -->

            <ng-template #depositExpandRowRef let-zoneCoin="element">
                <div class="mat-custom-table-expandBox my-2">
                    <div class="row">
                        <div class="col-12">
                            <div class="">
                                <div class="d-flex p-2 td-row">
                                    <p class="">{{ 'FEE_STRUCTURE.TABS.FULL_NAME' | translate }}</p>
                                    <span
                                        data-toggle="tooltip"
                                        data-placement="auto"
                                        tooltip="{{ zoneCoin?.coin_name }}">
                                        {{
                                            zoneCoin?.coin_name.length > 25
                                                ? (zoneCoin?.coin_name | slice : 0 : 25) + '..'
                                                : zoneCoin?.coin_name
                                        }}
                                    </span>
                                </div>
                                <div class="d-flex p-2 td-row">
                                    <p class="p-0">{{ 'FEE_STRUCTURE.TABS.NETWORK' | translate }}</p>
                                    <span>
                                        <span *ngFor="let network of zoneCoin.coin_networks">
                                            {{ network?.network_name }}
                                        </span>
                                    </span>
                                </div>
                                <div class="d-flex p-2 td-row">
                                    <p class="p-0">{{ 'FEE_STRUCTURE.TABS.WITH_FEE' | translate }}</p>
                                    <span>
                                        <span *ngFor="let network of zoneCoin.coin_networks">
                                            {{ network?.withdraw_fee }}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <!-- Expand row ref END  -->
            <section class="my-account-body">
                <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade show active" id="personalDetails" role="tabpanel">
                        <div class="row">
                            <div class="col-lg-8">
                                <h4 class="sub-title">
                                    <span> {{ 'FEE_STRUCTURE.DEPOSIT_WITHDRAWAL_FEE' | translate }}</span>
                                </h4>
                            </div>
                            <div class="col-lg-4 mb-2">
                                <div class="input-group flex-nowrap justify-content-end">
                                    <div class="form-outline w-100">
                                        <input
                                            type="search"
                                            (input)="onSearch($event)"
                                            (search)="onSearch($event)"
                                            id="form1"
                                            class="form-control"
                                            placeholder="{{ 'FEE_STRUCTURE.SEARCH_PLACEHOLDER' | translate }}..." />
                                    </div>
                                    <button type="button" class="btn-search">
                                        <em class="flaticon-magnifying-glass"></em>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive mt-3" *ngIf="!isLoading; else skeletonBox">
                            <app-custom-data-table
                                id="recentConvertTable"
                                class="table-shorting-ic"
                                [dynamicColumns]="depositColumnsConfig"
                                [expandViewTemplate]="depositExpandRowRef"
                                [isExpandableOnMobile]="true"
                                [isExpandableOnDesktop]="false"
                                [dataSet]="feeList"
                                [pageIndex]="page"
                                [pageSizeOptions]="[pageSize]"
                                [hideDefaultPaginator]="true"
                                [isLoading]="false"></app-custom-data-table>
                        </div>

                        <app-common-pagination
                            [data]="{
                                data: feeList,
                                page: page,
                                pageSize: pageSize,
                                collectionSize: feeList?.length
                            }"
                            [scrollDetail]="scrollRule"
                            (change)="onPageChange($event)"></app-common-pagination>
                    </div>

                    <!-- Spot Trading Tab -->

                    <div class="tab-pane fade" id="accountSecurity" role="tabpanel">
                        <h4 class="sub-title">
                            <span>{{ 'FEE_STRUCTURE.TRADING.SPOT_TRADING' | translate }}</span>
                        </h4>
                        <div class="table-responsive mt-3">
                            <app-custom-data-table
                                id="recentConvertTable"
                                class="table-shorting-ic"
                                [dynamicColumns]="spotTradingColumnsConfig"
                                [isExpandableOnMobile]="false"
                                [isExpandableOnDesktop]="false"
                                [dataSet]="spotTradeFeeList"
                                [hideDefaultPaginator]="true"
                                [pageIndex]="page"
                                [pageSizeOptions]="[pageSize]"
                                [isLoading]="false"></app-custom-data-table>
                        </div>
                        <app-common-pagination
                            [data]="{
                                data: spotTradeFeeList,
                                page: page,
                                pageSize: pageSize,
                                collectionSize: spotTradeFeeList?.length
                            }"
                            [scrollDetail]="scrollRule"
                            (change)="onPageChange($event)"></app-common-pagination>
                    </div>
                </div>
            </section>
        </div>
    </div>
</section>
