import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { isPlatformServer } from '@angular/common';
import { STORAGE_KEY } from '../../constants/enum.constants';
import { REQUEST } from '@nguniversal/express-engine/tokens';
const allowedApis = ['misc/release-info']; // Specify the allowed API paths
@Injectable()
export class StopApiInterceptor implements HttpInterceptor {
    constructor(@Inject(PLATFORM_ID) private platformId, @Optional() @Inject(REQUEST) private httpRequest) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let isLoggedIn = false;
        // || allowedApis.includes(request.url)
        if (this.httpRequest?.cookies && isPlatformServer(this.platformId)) {
            isLoggedIn = this.httpRequest?.cookies[STORAGE_KEY.IS_LOGGED_IN] === 'true';
        }
        if (isPlatformServer(this.platformId) && isLoggedIn) {
            // Running on the server, return original request without making API call
            return new Observable<HttpEvent<any>>(observer => observer.complete());
        }
        // Running on the client, continue with the API request
        return next.handle(request);
    }
}
