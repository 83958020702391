import { IxfiThemeService } from 'header';
import { Component, inject, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ApiService } from 'src/app/core';
import { environment } from 'src/environments/environment';
import { StorageService } from '../../../shared/services/storage.service';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';
import { intervalToDuration } from 'date-fns';
import { HttpClient } from '@angular/common/http';

interface SystemConfig {
    data: any;
    message: boolean;
    status: number;
}
@Component({
    selector: 'app-server-management',
    templateUrl: './server-management.component.html',
    styleUrls: ['./server-management.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ServerManagementComponent implements OnInit, OnDestroy {
    CDN_URL = environment.CDN_URL;
    subscription: Subscription;
    constructor(
        private api: ApiService,
        private router: Router,
        private storage: StorageService,
        private themeService: IxfiThemeService,
        @Inject(PLATFORM_ID) private platformId: object
    ) {}
    private http = inject(HttpClient);
    isMaintenanceModeOn = false;
    maintenanceEndTime = 0;
    eventTime; // UTC Time - 2024-02-16T11:15:30.000Z
    currentTime = moment(new Date()).unix(); // Timestamp - Sun, 21 Apr 2013 12:30:00 GMT
    diffTime;
    interval = 1000;
    handleInterval;
    data: any;
    retryAfter!: Date;
    maintenanceTime;
    duration: Duration | any = { hours: undefined, minutes: undefined, seconds: undefined };
    intervalCallback: number | undefined;
    durationCallBack!: ReturnType<typeof setInterval>;
    ngOnInit(): void {
        // init defaultTheme manually since header component is not used here
        this.themeService.setDefaultTheme();
        if (isPlatformBrowser(this.platformId)) {
            this.checkForUpdates();

            /* Get `Retry-After` value from Local Storage */
            const dateFromStorage: string | null = localStorage.getItem('Retry-After');

            /**
             * If dateFromStorage is a valid truth-ify value, then assign it to `retryAfter` Date variable
             * Else, assign the current date
             */
            if (dateFromStorage && dateFromStorage.length > 0) this.retryAfter = new Date(dateFromStorage);
            else this.retryAfter = new Date();

            /**
             * Calculate Duration between now and the `retryAfter` date
             * Update this value every second by running it in setInterval
             */
            this.durationCallBack = setInterval(() => {
                this.duration = intervalToDuration({ start: new Date(), end: this.retryAfter });
            }, 1000);

            /**
             * If Maintenance mode is on, send a HTTP request every 3 seconds
             * to check the status of the system
             */
            if (this.isMaintenanceModeOn) {
                this.intervalCallback = setInterval(() => {
                    this.checkForUpdates();
                }, 3000) as any;
            } else {
                if (this.intervalCallback !== undefined) {
                    clearInterval(this.intervalCallback);
                }
            }
        }
        // try {
        //     let dateNew: any = this.storage.getItem('Retry-After');

        //     if (dateNew && dateNew != 'undefined' && dateNew?.length) {
        //         dateNew = moment(dateNew);
        //     } else {
        //         dateNew = moment();
        //     }
        //     this.eventTime = moment(dateNew).unix();

        //     this.diffTime = this.eventTime - this.currentTime;

        //     this.duration = moment.duration(this.diffTime * 1000, 'milliseconds');
        // } catch (error) {
        //     console.log('server man', error);
        // }

        // if (isPlatformBrowser(this.platformId)) {
        //     const _this = this;
        //     this.handleInterval = setInterval(function () {
        //         _this.duration = moment.duration(_this.duration - _this.interval, 'milliseconds');
        //         if (_this.duration._milliseconds <= 0) {
        //             _this.checkForUpdates();
        //         }
        //     }, _this.interval);

        //     setTimeout(() => {
        //         this.checkForUpdates();
        //     }, this.duration / 2);
        // }
        // console.log('server manage');
    }

    private checkForUpdates() {
        // this.subscription = this.api.get('misc/release-info?release_platform=backend').subscribe({
        //     // https: this.api.get('misc/get-system-config').subscribe({
        //     next: res => {
        //         this.isMaintenanceModeOn = res.data.system_config.is_maintenance_on;
        //         if (res.status === 200 && this.isMaintenanceModeOn) {
        //             this.storage.setItem('Retry-After', res?.data?.system_config?.maintenance_end_time);
        //             this.eventTime = moment(res?.data?.system_config?.maintenance_end_time).unix();
        //             this.diffTime = this.eventTime - this.currentTime;

        //             this.duration = moment.duration(this.diffTime * 1000, 'milliseconds');
        //         } else {
        //             this.storage.removeItem('Retry-After');
        //             this.router.navigate(['/landing']);
        //             clearInterval(this.handleInterval);
        //             this.handleInterval = 0;
        //         }
        //         // this.data = res.data;
        //         // this.isMaintenanceModeOn = this.data.is_maintenance_on;
        //         // if (res.status === 200 && this.isMaintenanceModeOn) {
        //         //     if (this.data.is_global_maintenance_on) {
        //         //         if (this.data.global && this.data.global.is_maintenance_on) {
        //         //             this.eventTime = this.data.global['maintenance_end_time'];
        //         //         }
        //         //     } else {
        //         //         if (this.data.main_exchange && this.data.main_exchange.is_maintenance_on) {
        //         //             this.eventTime = this.data.main_exchange['maintenance_end_time'];
        //         //         } else if (this.data.web && this.data.web.is_maintenance_on) {
        //         //             this.eventTime = this.data.web['maintenance_end_time'];
        //         //         }
        //         //     }

        //         //     this.storage.setItem('Retry-After', JSON.stringify(this.eventTime));
        //         //     let time = moment(this.eventTime).unix();
        //         //     this.diffTime = time - this.currentTime;
        //         //     this.duration = moment.duration(this.diffTime * 1000, 'milliseconds');
        //         // } else {
        //         //     this.storage.removeItem('Retry-After');
        //         //     this.router.navigate(['/landing']);
        //         //     clearInterval(this.handleInterval);
        //         //     this.handleInterval = 0;
        //         // }
        //     },
        //     error: () => this.router.navigate(['/landing'])
        // });

        this.subscription = this.http
            .get<{ status: number; message: string; data: { system_config: SystemConfig } }>(
                `${environment.IXFI_API}misc/get-system-config?server_name=ALL`
            )
            .subscribe({
                next: response => {
                    /* Check If maintenance mode is on */

                    this.data = response.data;
                    this.isMaintenanceModeOn = this.data.is_maintenance_on;
                    console.log(response);
                    if (response.status === 200 && this.isMaintenanceModeOn) {
                        if (this.data.is_global_maintenance_on) {
                            if (this.data?.global?.is_maintenance_on) {
                                this.maintenanceTime = this.data.global['maintenance_end_time'];
                            }
                        } else {
                            if (this.data?.main_exchange?.is_maintenance_on) {
                                this.maintenanceTime = this.data.main_exchange['maintenance_end_time'];
                            } else if (this.data?.web?.is_maintenance_on) {
                                this.maintenanceTime = this.data.web['maintenance_end_time'];
                            } else if (this.data.web.research && this.data.web.research.is_maintenance_on) {
                                this.maintenanceTime = this.data.web.research['maintenance_end_time'];
                            } else if (this.data.coin_info && this.data.coin_info.is_maintenance_on)
                                this.maintenanceTime = this.data.coin_info['maintenance_end_time'];
                            else {
                                this.maintenanceTime = this.data['maintenance_end_time'];
                            }
                        }

                        localStorage.setItem('Retry-After', JSON.stringify(this.maintenanceTime));
                        /* Update the retryAfter Date */
                        this.retryAfter = new Date(this.maintenanceTime);
                        /* Update the Duration Timer */
                        this.duration = intervalToDuration({ start: new Date(), end: this.retryAfter });
                    } else {
                        localStorage.removeItem('Retry-After');
                        this.router.navigate(['/']);
                        clearInterval(this.durationCallBack);
                    }
                },
                error: _ => this.router.navigate(['/'])
            });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.durationCallBack) clearInterval(this.durationCallBack);
        if (this.intervalCallback) clearInterval(this.intervalCallback);
    }
}
