import { Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, ViewChild, inject } from '@angular/core';
import {
    ReactiveFormsModule,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CountryService } from 'src/app/core';
import { unfreezeInput, customSearch } from 'src/app/core/utils';
import { CountrySelectComponent } from '../country-select/country-select.component';
import { NgSelectModule } from '@ng-select/ng-select';

export enum COMPLIANCE_TYPE {
    TASK_UBO = 1,
    TASK_CHECK_PEP = 2,
    TASK_PEP_YES = 3,
    TASK_PEP_DONE = 4
}

@Component({
    selector: 'app-compliance',
    templateUrl: './compliance.component.html',
    styleUrls: ['./compliance.component.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule, NgSelectModule, ReactiveFormsModule]
})
export class ComplianceComponent implements OnInit {
    @Input() data: { modalRef: NgbModalRef; type: COMPLIANCE_TYPE };
    CDN = environment.CDN_URL;
    BASE_URL = environment.IXFI_EXCHANGE;
    //country list
    @ViewChild('phoneCodeSelect') countryCode: ElementRef;
    public countryList: Array<any> = [];
    complianceForm: UntypedFormGroup;
    pepValue = '';
    private countryService = inject(CountryService);
    constructor(private fb: UntypedFormBuilder, @Inject(PLATFORM_ID) private platformId: object) {}
    COMPLIANCE_TYPE = COMPLIANCE_TYPE;
    pepSelection = false;
    nonPEPSelection = false;
    showForm: boolean;

    public unfreezeInput = unfreezeInput;
    public customSearch = customSearch;
    private modalService = inject(NgbModal);
    ngOnInit(): void {
        this.getCountryCode();

        this.complianceForm = this.fb.group({
            pep: '',
            notpep: this.fb.array([])
        });
    }

    get notPEP(): UntypedFormArray {
        return <UntypedFormArray>this.complianceForm.controls['notpep'];
    }
    addNotPEP() {
        this.notPEP.push(this.pepFormGroup());
    }

    removeNotPEP(i: number) {
        this.notPEP.removeAt(i);
        if (!this.notPEP.length) this.nonPEPSelection = false;
    }
    pepFormGroup(): UntypedFormGroup {
        return this.fb.group({
            fullname: ['', Validators.required],
            country: ['', Validators.required],
            position: ['', Validators.required]
        });
    }

    //for country
    focusInputField() {
        if (isPlatformBrowser(this.platformId)) setTimeout(() => this.countryCode.nativeElement?.focus(), 10);
    }

    private getCountryCode() {
        this.countryService.getStaticCountryData().then((res: any) => {
            this.countryList = res.countryList;
        });
    }
    // country list

    taskUBO(status) {
        this.data.modalRef.close({ type: 'UBO', status: true, data: status == 'confirm' });
    }

    taskCheckPEP(status) {
        this.pepValue = status;
        if (status === 'next') {
            this.showForm = true;
            return;
        }
        if (status === 'confirm') this.data.modalRef.close({ type: 'PEP', data: { pep_form: false } });
    }

    taskCheckPEPYes() {
        this.complianceForm.markAllAsTouched();

        if (this.checkPepCondition()) return;

        const data = { pep_form: true, ...this.complianceForm.value };

        if (!this.nonPEPSelection && !this.notPEP.length) delete data.notpep;
        this.data.modalRef.close({ type: 'PEP_YES', data });
    }

    checkPepCondition() {
        return (
            (!this.pepSelection && !this.nonPEPSelection) ||
            (this.complianceForm.invalid && this.pepSelection) ||
            (this.notPEP.status === 'INVALID' && this.nonPEPSelection)
        );
    }

    pepYesSelection(event, type) {
        if (type == 'pep') {
            this.pepSelection = event.target.checked;
            this.pepSelection ? this.addValidation() : this.removeValidation();
        }

        if (type == 'nonPEP') {
            this.nonPEPSelection = event.target.checked;
            if (!this.nonPEPSelection) this.notPEP.clear();
            if (this.nonPEPSelection && !this.notPEP.controls.length) this.addNotPEP();
        }
    }

    addValidation() {
        this.complianceForm.removeControl('pep');
        this.complianceForm.addControl('pep', this.pepFormGroup());
    }
    removeValidation() {
        this.complianceForm.removeControl('pep');
        this.complianceForm.addControl('pep', new UntypedFormControl());
    }

    resetForm() {
        this.showForm = this.pepSelection = this.nonPEPSelection = false;
        this.notPEP.clear();
        this.complianceForm.reset();
        this.pepValue = '';
    }
    openCountryModal(index) {
        const modalRef = this.modalService.open(CountrySelectComponent, {
            size: 'md',
            centered: true,
            backdrop: true,
            modalDialogClass: 'convert-modal modal-bottom-sheet select-box'
        });
        modalRef.componentInstance.data = {
            modalRef: modalRef,
            countries: this.countryList
        };
        modalRef.result.then(({ status, selected }) => {
            if (status === 200) {
                const control = <UntypedFormArray>this.complianceForm.controls['notpep'];
                control.at(index)['country'].patchValue(selected.name);
            }
        });
    }

    done() {
        this.data.modalRef.close({ type: 'PEP_DONE', status: true, data: true });
    }

    back() {
        this.showForm = false;
        this.complianceForm.reset();
    }
}
