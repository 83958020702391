<div class="modal-header">
    <h5 class="mb-0" [ngSwitch]="data.label">
        <ng-container *ngSwitchCase="'state'">Select state</ng-container>
        <ng-container *ngSwitchCase="'city'">Select city</ng-container>
        <ng-container *ngSwitchDefault>Country code</ng-container>
    </h5>
    <button type="button" (click)="closeModal(404, '')" class="btn-close">
        <em class="flaticon-close"></em>
    </button>
</div>

<div class="country-select-box">
    <div class="d-flex align-items-center search-item mb-3">
        <input
            type="search"
            #countriesSearch
            placeholder="Search..."
            class="form-control"
            (keyup)="customSearch(countriesSearch.value)" />
        <button type="button" class="close-btn" (click)="resetCountries()" [ngClass]="{ 'd-none': searchTerm == '' }">
            <em class="flaticon-close"></em>
        </button>
    </div>
    <div class="country-list" *ngIf="countryList">
        <div class="country-row d-flex align-items-center justify-content-between" *ngFor="let country of countryList">
            <div class="d-flex align-items-center w-100" (click)="selected(country)">
                <div class="country-img" *ngIf="country.id">
                    <img [alt]="country.name" loading="lazy" [src]="CDN + 'country/' + country.id + '.png'" />
                </div>
                <div class="country-name" *ngIf="country.phonecode">
                    <span class="coin_short_name"> {{ country.name }}</span
                    ><span class="coin_full_name"> +{{ country.phonecode }}</span>
                </div>
                <div class="country-name" *ngIf="!country.phonecode">
                    <span class="coin_short_name"> {{ country.name }} </span>
                </div>
            </div>
        </div>
    </div>

    <div
        class="country-row d-flex align-items-center justify-content-between"
        *ngIf="!countryList || countryList.length === 0">
        <div class="country-name">
            <span class="coin_short_name">No Data Available </span>
        </div>
    </div>
</div>
