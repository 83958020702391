<ng-container *ngIf="data?.type === POP_UP_TYPE.LOG_OUT">
    <div class="modal-header border-0">
        <button type="button" class="btn-close" (click)="onCancel()"><em class="flaticon-close"></em></button>
    </div>
    <div class="modal-body text-center pt-0">
        <img class="icon-width-nav" [src]="CDN_URL + 'new-landing/Logout_9.png'" alt="Logout_" />

        <h5>{{ 'LOGOUT.TITLE' | translate }}</h5>
        <p>{{ 'LOGOUT.TEXT' | translate }}</p>
        <div class="d-flex gap-2 justify-content-center">
            <button type="submit" class="btn-twice-outline" (click)="onLogout()">
                {{ 'LOGOUT.YES' | translate }}
            </button>
            <button type="submit" class="btn-default btn-secondary" (click)="onCancel()">
                {{ 'LOGOUT.NO' | translate }}
            </button>
        </div>
    </div>
</ng-container>

<!-- =============================== Start Session Modal  ============================== -->
<ng-container *ngIf="data?.type === POP_UP_TYPE.SESSION_OUT">
    <div class="modal-header border-0 pe-0">
        <button type="button" class="btn-close" (click)="onCancel()"><em class="flaticon-close"></em></button>
    </div>
    <div class="modal-body text-center session-modal">
        <h5>{{ 'SESSION_OUT.TITLE' | translate }}</h5>
        <div class="lottie-container">
            <ng-lottie [options]="{ path: CDN_URL + 'session-expired.json' }" width="190px"> </ng-lottie>
        </div>
        <p>
            {{ 'SESSION_OUT.TEXT_1' | translate }}
            <br />{{ 'SESSION_OUT.TEXT_2' | translate }} <strong> {{ 'SESSION_OUT.YES' | translate }} </strong>
            {{ 'SESSION_OUT.TEXT_3' | translate }}
        </p>
        <button class="btn-default btn-secondary w-100" (click)="onOK()">
            {{ 'SESSION_OUT.YES' | translate }}
        </button>
        <div class="skip-link">
            <a (click)="onCancel()">{{ 'SESSION_OUT.NO' | translate }}</a>
        </div>
    </div>
</ng-container>

<!-- =============================== End Session Modal  ============================== -->
<ng-container *ngIf="data?.type === POP_UP_TYPE.LOGGED_OUT">
    <div class="modal-header border-0 pe-0">
        <button type="button" class="btn-close" (click)="onCancel()"><em class="flaticon-close"></em></button>
    </div>
    <div class="modal-body text-center session-modal">
        <h5>{{ 'LOGGED_OUT.TITLE' | translate }}</h5>
        <div class="lottie-container">
            <ng-lottie [options]="{ path: CDN_URL + 'session-expired.json' }" width="190px"> </ng-lottie>
        </div>
        <p>
            {{ 'LOGGED_OUT.TEXT_1' | translate }}
            <br />{{ 'LOGGED_OUT.TEXT_2' | translate }} <strong> {{ 'LOGGED_OUT.YES' | translate }} </strong>
            {{ 'LOGGED_OUT.TEXT_3' | translate }}
        </p>
        <button class="btn-default btn-secondary w-100" (click)="onOK()">
            {{ 'LOGGED_OUT.YES' | translate }}
        </button>
        <div class="skip-link">
            <a (click)="onCancel()">{{ 'LOGGED_OUT.NO' | translate }}</a>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="data?.type === POP_UP_TYPE.YES_NO">
    <div class="modal-header border-0">
        <button type="button" class="btn-close" (click)="onCancel()"><em class="flaticon-close"></em></button>
    </div>
    <div class="modal-body text-center pt-0">
        <img class="icon-width-nav" [src]="CDN_URL + 'coinInfo/icon/Logout.svg'" alt="Logout" />
        <h5>{{ 'YES_NO.TITLE' | translate }}</h5>
        <p>{{ 'YES_NO.TEXT' | translate }}</p>
        <div class="d-flex justify-content-center mx-auto gap-2 flex-sm-row flex-column">
            <button type="submit" class="btn-twice-outline" (click)="onOK()">
                {{ 'YES_NO.YES' | translate }}
            </button>
            <button type="submit" class="btn-default btn-secondary w-100" (click)="onCancel()">
                {{ 'YES_NO.NO' | translate }}
            </button>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="data?.type === POP_UP_TYPE.AUTHENTICATOR_BACKUP_CODE">
    <div class="modal-header border-0">
        <button type="button" class="btn-close" (click)="onCancel()"><em class="flaticon-close"></em></button>
    </div>
    <div class="modal-body text-center pt-0">
        <img class="icon-width-nav" [src]="CDN_URL + 'coinInfo/icon/Logout.svg'" alt="Logout" />
        <h5>{{ 'YES_NO.TITLE' | translate }}</h5>
        <p>{{ 'YES_NO.BACKUP_TEXT' | translate }}</p>
        <div class="d-flex justify-content-center mx-auto gap-2 flex-sm-row flex-column">
            <button type="submit" class="btn-twice-outline" (click)="onOK()">
                {{ 'YES_NO.YES' | translate }}
            </button>
            <button type="submit" class="btn-default btn-secondary w-100" (click)="onCancel()">
                {{ 'YES_NO.NO' | translate }}
            </button>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="data?.type === POP_UP_TYPE.GENERATE_BACKUP">
    <div class="modal-header">
        <h5>{{ 'GENERATE_BACKUP.TITLE' | translate }}</h5>
        <button type="button" class="btn-close" (click)="onCancel()">
            <em class="flaticon-close"></em>
        </button>
    </div>
    <div class="modal-body text-center generate-code-modal">
        <p>{{ 'GENERATE_BACKUP.TEXT' | translate }}</p>
        <div class="d-flex justify-content-center mx-auto gap-2 flex-sm-row flex-column">
            <button type="submit" class="btn btn-generate" (click)="onOK()">
                {{ 'GENERATE_BACKUP.YES' | translate }}
            </button>
            <button type="submit" class="btn-default w-100" (click)="onCancel()">
                {{ 'GENERATE_BACKUP.NO' | translate }}
            </button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="data?.type === POP_UP_TYPE.SUCCESS_NEWSLETTER">
    <div class="modal-body pt-2 text-center">
        <div class="align-center mb-3 remove-modal">
            <img [src]="CDN_URL + 'my-account/Newsletter.png'" alt="Newsletter" class="img-fluid" />
        </div>
        <h5>{{ 'SUCCESS_NEWS_LETTER.TITLE' | translate }}</h5>
        <p class="pt-1 pb-1">{{ 'SUCCESS_NEWS_LETTER.TEXT' | translate }}</p>
        <div>
            <button type="submit" class="btn-default btn-secondary w-100" (click)="onOK()">
                {{ 'SUCCESS_NEWS_LETTER.THANK_YOU' | translate }}
            </button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="data?.type === POP_UP_TYPE.ACCOUNT_DISABLE">
    <div class="modal-header border-0">
        <button type="button" class="btn-close" (click)="onCancel()"><em class="flaticon-close"></em></button>
    </div>
    <div class="modal-body text-center pt-0">
        <img class="icon-width-nav" [src]="CDN_URL + 'new-landing/Account-Disabled.webp'" alt="Account-Disabled" />
        <h5>{{ 'GENERAL.ACCOUNT_DISABLED_TITLE' | translate }}</h5>
        <p>
            {{ 'GENERAL.ACCOUNT_DISABLED_TEXT' | translate }}
            <a class="cursor-pointer" href="mailto:support@ixfi.com">{{'support@ixfi.com'}}</a>
            {{ 'GENERAL.ACCOUNT_DISABLED_TEXT_TWO' | translate }}
        </p>
        <div>
            <button type="submit" class="btn-default btn-secondary w-100" (click)="onCancel()">
                {{ 'GENERAL.CONTACT_SUPPORT' | translate }}
            </button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="data?.type === POP_UP_TYPE.DISABLE_AUTH_SUCCESS">
    <div class="modal-header popup-modal border-0 mb-0 pb-0">
        <button type="button" (click)="onCancel()" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <em class="flaticon-close"></em>
        </button>
    </div>
    <div class="modal-body text-center p-0">
        <div class="align-center mb-3 mt-3 disable-modal">
            <img [src]="CDN_URL + 'my-account/disable.png'" alt="disable" class="img-fluid" />
        </div>
        <p class="">{{ 'TWO-FACTOR-LOGIN.DISABLE_2FA_TEXT' | translate }}</p>
        <div>
            <button type="submit" class="btn btn-default modal-btn w-100 px-5 me-2 text-center mb-3"
                (click)="onCancel()">
                {{ 'TWO-FACTOR-LOGIN.DONE_TEXT' | translate }}
            </button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="data?.type === POP_UP_TYPE.RESET_SUCCESS">
    <div class="modal-header popup-modal border-0 pb-0">
        <button type="button" (click)="onCancelLogout()" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <em class="flaticon-close"></em>
        </button>
    </div>
    <div class="modal-body text-center">
        <div class="align-center mb-3 remove-modal">
            <img [src]="CDN_URL + 'my-account/2FA-Auth-remove.png'" alt="2FA-Auth-remove" class="img-fluid" />
        </div>
        <p>{{ 'TWO-FACTOR-LOGIN.2FA_EMAIL_LINK_TEXT' | translate }}</p>
        <div>
            <button type="submit" class="btn btn-default btn-secondary w-100 px-5 me-2 text-center mb-3"
                (click)="onCancelLogout()">
                {{ 'COOKIE_POLICY.OK_TEXT' | translate }}
            </button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="data?.type === POP_UP_TYPE.RESET_CONFIRMATION">
    <div class="modal-header border-0">
        <button type="button" class="btn-close" (click)="resetConfirmation(false)">
            <em class="flaticon-close"></em>
        </button>
    </div>
    <div class="modal-body text-center pt-0">
        <img class="icon-width-nav" [src]="CDN_URL + 'coinInfo/icon/Logout.svg'" alt="Logout" />
        <h5>{{ 'YES_NO.RESET_TITLE' | translate }}</h5>
        <p>{{ 'YES_NO.RESET_TEXT' | translate }}</p>
        <div class="d-flex justify-content-center gap-2">
            <button type="submit" class="btn-twice-outline" (click)="resetConfirmation(true)">
                {{ 'YES_NO.YES' | translate }}
            </button>
            <button type="submit" class="btn-default btn-secondary" (click)="resetConfirmation(false)">
                {{ 'YES_NO.NO' | translate }}
            </button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="data?.type === POP_UP_TYPE.BACKUP_CODE_LOGIN_PART_A">
    <div class="modal-header">
        <h5>{{ 'YES_NO.BACKUP_CODE_LOGIN' | translate }}</h5>
        <button type="button" class="btn-close" (click)="onBackupCodeLogin(false)">
            <em class="flaticon-close"></em>
        </button>
    </div>
    <div class="modal-body text-center pt-2 session-modal">
        <p>{{ 'YES_NO.BACKUP_CODE_TEXT_1' | translate }}</p>
        <button class="btn-default btn-secondary w-100" (click)="onBackupCodeLogin(false)">
            {{ 'YES_NO.CANCEL' | translate }}
        </button>

        <div class="skip-link text-center">
            <a href="javascript:;" (click)="onBackupCodeLogin(true)">
                {{ 'YES_NO.REMOVE' | translate }}
            </a>
        </div>
    </div>
</ng-container>

<!-----  Request Remove Authenticator  -------->
<ng-container *ngIf="data?.type === POP_UP_TYPE.BACKUP_CODE_LOGIN_PART_B">
    <div class="modal-header">
        <h5>{{ 'YES_NO.RESET_TITLE' | translate }}</h5>
        <button type="button" class="btn-close" (click)="onBackupCodeLogin(false)">
            <em class="flaticon-close"></em>
        </button>
    </div>
    <div class="modal-body generate-code-modal session-modal">
        <div>
            <ol class="modal-list">
                <li>{{ 'YES_NO.BACKUP_CODE_TEXT_2_1' | translate }}</li>
                <li>{{ 'YES_NO.BACKUP_CODE_TEXT_2_2' | translate }}</li>
                <ul>
                    <li>{{ 'YES_NO.BACKUP_CODE_TEXT_2_A' | translate }}</li>
                    <li>{{ 'YES_NO.BACKUP_CODE_TEXT_2_B' | translate }}</li>
                    <li>{{ 'YES_NO.BACKUP_CODE_TEXT_2_C' | translate }}</li>
                </ul>
                <li>{{ 'YES_NO.BACKUP_CODE_TEXT_2_D' | translate }}</li>
            </ol>
        </div>
        <button class="btn-default btn-secondary w-100" (click)="onBackupCodeLogin(false)">
            {{ 'YES_NO.CANCEL' | translate }}
        </button>
        <div class="skip-link text-center">
            <a href="javascript:void(0)" (click)="onBackupCodeLogin(true)">
                {{ 'YES_NO.REMOVE' | translate }}
            </a>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="data?.type === POP_UP_TYPE.TASK_THANKS">
    <div class="modal-header border-0">
        <button type="button" class="btn-close"><em class="flaticon-close" (click)="onCancel()"></em></button>
    </div>
    <div class="modal-body text-center pt-0">
        <img class="img-thanks" [src]="CDN_URL + 'personal-detail/thanks.svg'" alt="thanks" />
        <h5>Thank You</h5>
        <p>You have successfully completed the verification. Now you can enjoy IXFI</p>

        <div class="mt-4">
            <button type="submit" class="btn-default btn-secondary" (click)="onCancel()">Done</button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="data?.type === POP_UP_TYPE.TRUSTED_DEVICE_MAX_LIMIT">
    <div class="modal-header border-0">
        <button type="button" class="btn-close"><em class="flaticon-close" (click)="onCancel()"></em></button>
    </div>
    <div class="modal-body text-center pt-0">
        <img class="img-thanks" [src]="CDN_URL + 'personal-detail/thanks.svg'" alt="thanks" />
        <p>{{ 'TRUSTED_D.MAXIMUM_LIMIT' | translate }}</p>

        <div class="mt-4">
            <button type="submit" class="button-xxl btn-default" (click)="onCancel()">
                {{ 'TRUSTED_D.OK_TEXT' | translate }}
            </button>
        </div>
    </div>
</ng-container>