import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Currency, IxfiCurrencyService, IxfiLanguageService, Language } from 'header';
import { Observable, catchError, combineLatest, of, retry } from 'rxjs';
import { IntercomService } from '../../shared/services/intercom.service';
import { CoinInfoJson } from '../models/coin-info-json.model';
import { CoinInfoService } from './coin-info.service';
import { HttpErrorResponse } from '@angular/common/http';

interface AppResolverData {
    language: Language[];
    currency: Currency[];
    coinInfo: CoinInfoJson;
}

@Injectable({
    providedIn: 'root'
})
export class AppResolver {
    constructor(
        private language: IxfiLanguageService,
        private currency: IxfiCurrencyService,
        private coinInfo: CoinInfoService,
        private router: Router,
        _intercomService: IntercomService // keep the service to initialize the chat
    ) {}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AppResolverData | any> {
        return combineLatest({
            language: this.language.fetchLanguageList().pipe(retry(3)),
            currency: this.currency.fetchCurrencyList().pipe(retry(3)),
            coinInfo: this.coinInfo.fetchCoinInfo().pipe(retry(3))
        }).pipe(
            catchError((error: HttpErrorResponse) => {
                // Handle error here
                console.log('AppResolver error: ', error);
                if (error.status === 503) this.router.navigate(['/server-management']);
                // Continue resolving or redirect based on the error
                return of({});
            })
        );
    }
}
