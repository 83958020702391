import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { CustomTemplatesService } from '../services/custom-templates.service';
import {
    AfterViewInit,
    Component,
    Input,
    OnInit,
    ViewChild,
    TemplateRef,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    Inject,
    PLATFORM_ID,
    OnDestroy
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { detailExpand } from '../animations/animations';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';

interface ColumnBreakpoints {
    [breakpoint: string]: string[];
}
@Component({
    selector: 'app-custom-data-table',
    templateUrl: './custom-data-table.component.html',
    styleUrls: ['./custom-data-table.component.scss'],
    animations: [detailExpand],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomDataTableComponent implements OnInit, AfterViewInit, OnDestroy {
    breakPoints = {
        1200: '(max-width: 1200px)',
        992: '(max-width: 992px)',
        768: '(max-width: 768px)',
        576: '(max-width: 576px)',
        375: '(max-width: 375px)',
        319: '(max-width: 319px)'
    };
    // _ActionButtonTemplate: TemplateRef<any>;
    // using any Type for now, will switch to a better type system

    /**
     * The data set to be displayed in the table
     * @param value - data set
     */
    @Input() set dataSet(value: any) {
        this.dataSource = new MatTableDataSource<any>(value);
        this.InitSortPagination();
    }
    /**
     * Enable/Disable action column if any action button template is passed
     * Action button template to be displayed in the action column of the table
     * @param value - template reference
     */
    // @Input() set ActionButtonTemplate(value: TemplateRef<any>) {
    //     this._ActionButtonTemplate = value;
    //     if (!this._ActionButtonTemplate) {
    //         this.displayedColumns.splice(this.displayedColumns.indexOf('actions'), 1);
    //     }
    // }

    @Input() trackByIdKeyName = '_id';

    /**
     * Filter table based on the value
     * @param value - filter value
     */
    @Input() set filterTable(value: any) {
        this.applyFilter(value);
    }

    @Input() noDataLabel = 'No data available';

    @Input() dynamicColumns: any[] = [];
    @Input() columnBreakpoints: ColumnBreakpoints = {};
    @Input() rowPanelClass: string[] = [];
    @Input() stickyColumnColor = '';

    // Expand
    isExpandable = false;
    @Input() expandColumnName = '';
    @Input() isExpandableOnMobile = false;
    @Input() isExpandableOnDesktop = false;
    @Input() stickyColumnName = '';
    @Input() isHeaderSticky = false;
    @Input() expandViewTemplate: TemplateRef<any>;
    @Input() expandUpArrowTemplate: TemplateRef<any>;
    @Input() expandDownArrowTemplate: TemplateRef<any>;
    @Output() expandedElementChange = new EventEmitter<any>();

    private _expandedElement: unknown | null;
    // Setter method for expandedElement

    set expandedElement(value: any) {
        this._expandedElement = value;
        this.expandedElementChange.emit(value);
    }
    // Getter method for expandedElement
    get expandedElement(): any {
        return this._expandedElement;
    }
    // Paginator
    @Input() isPaginationServerSide = false;
    @Input() pageSizeOptions: number[] = [];
    @Input() pageSize: number = 50;
    @Input() paginatorTotalRows: number;
    @Input() hideDefaultPaginator = false;
    @Input() set pageIndex(value: number) {
        if (this.paginator) {
            this.paginator.pageIndex = value - 1;
            this.paginator.page.emit();
        }
    }
    @Input() page_index: number;

    @Input() hidePageSize = true;
    @Output() pageChange = new EventEmitter<any>();

    // Sort
    @Input() isSortServerSide = false;
    @Input() activeSortColumn = '';
    @Input() matSortDirection: 'asc' | 'desc' = 'desc';
    @Output() sortChange = new EventEmitter<any>();
    @Output() refSortChange = new EventEmitter<any>();

    // Search
    @Input() showSearch = false;
    @Input() isSearchServerSide = false;
    @Output() searchEvent = new EventEmitter<string>();

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort!: MatSort;

    textList: string[] = [];

    searchFilter = '';
    dataSource = new MatTableDataSource<any>();

    displayedColumns: any[] = [];
    hideColumnsOnResponsive = false;
    @Input() isLoading = true;
    isBrowser!: boolean;
    currentBreakPoint: any;
    constructor(
        public templateService: CustomTemplatesService,
        private cdr: ChangeDetectorRef,
        private breakpointObserver: BreakpointObserver,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }
    CDN_URL = environment.CDN_URL;
    bpSub!: Subscription;

    options = { path: this.CDN_URL + 'lottie/no_data.json' };
    ngOnInit(): void {
        // This will add the this.InitBreakPointObserver(),
        // function to the microtask queue, which will be executed after the current call stack is cleared.
        Promise.resolve().then(() => {
            this.InitBreakPointObserver();
        });
        // this.initColumns();
    }
    trackByFn = (index: number, item: any): any => {
        if (!this.trackByIdKeyName) {
            return null;
        }
        return item[this.trackByIdKeyName];
    };

    getTextToCopy(str: string) {
        if (typeof str === 'string') {
            return str.replace(/file_copy/g, '');
        } else return '';
    }

    initColumns(): void {
        // column names to an array displayedColumns
        this.displayedColumns = this.dynamicColumns
            .sort((a, b) => a.columnPosition - b.columnPosition)
            .map(el => el.columnDef);

        // this.displayedColumns.unshift('mergedColumn')

        this.addActionExpandColumns();

        this.cdr.detectChanges();
    }

    addActionExpandColumns() {
        // Add/remove action column if template is provided
        // this.displayedColumns.push('actions');
        // if (!this._ActionButtonTemplate) {
        //     this.displayedColumns.splice(this.displayedColumns.indexOf('actions'), 1);
        // }

        // Add/remove expand column if isExpandable is true
        if (this.isExpandable && (this.isExpandableOnMobile || this.isExpandableOnDesktop))
            this.displayedColumns.push('expand');
    }

    InitBreakPointObserver(): void {
        this.bpSub = this.breakpointObserver
            .observe([
                this.breakPoints[1200],
                this.breakPoints[992],
                this.breakPoints[768],
                this.breakPoints[576],
                this.breakPoints[375],
                this.breakPoints[319]
            ])
            .subscribe((state: BreakpointState) => {
                this.currentBreakPoint = state.breakpoints;
                if (Object.values(this.currentBreakPoint)[0]) {
                    if (this.isExpandableOnMobile) this.isExpandable = true;
                    this.hideColumnsOnResponsive = true;
                    this.initColumns();
                    this.removeMergedColumns();
                } else {
                    if (this.isExpandableOnDesktop) this.isExpandable = true;
                    else this.isExpandable = false;

                    this.hideColumnsOnResponsive = false;
                    this.initColumns();
                }
                this.cdr.detectChanges();
            });
    }
    removeMergedColumns(): void {
        // Add columns based on resolutions
        for (let key in this.columnBreakpoints) {
            if (this.currentBreakPoint[key] && this.columnBreakpoints[key]?.length > 0) {
                this.displayedColumns = [...this.columnBreakpoints[key]];
                this.addActionExpandColumns();
            }
        }

        if (Object.values(this.columnBreakpoints).length < 1) {
            this.dynamicColumns.forEach((el: any) => {
                if (el?.hideColumnOnMerge === true) {
                    this.displayedColumns.splice(this.displayedColumns.indexOf(el.columnDef), 1);
                }
            });
        }
    }

    ngAfterViewInit() {
        this.InitSortPagination();
        // if (this.breakpointObserver.isMatched('(max-width: 1200px)')) {
        //     if (this.isExpandableOnMobile) this.isExpandable = true;
        //     this.hideColumnsOnResponsive = true;
        //     this.initColumns();
        //     this.removeMergedColumns();
        // } else {
        //     if (this.isExpandableOnDesktop) this.isExpandable = true;
        //     else this.isExpandable = false;
        //     this.hideColumnsOnResponsive = false;
        //     this.initColumns();
        // }
        // this.cdr.detectChanges();
    }
    sortData(sort: MatSort) {
        if (sort.direction === '') {
            this.sort.sort({ id: this.activeSortColumn, start: this.matSortDirection, disableClear: false });
        }
        // reset to first page on sort
        if (!this.hideDefaultPaginator) this.paginator.pageIndex = 0;
    }
    // Paginator page change event
    handlePageEvent(page: PageEvent) {
        this.pageChange.emit(page);
    }

    // Init Sort And Pagination
    InitSortPagination(): void {
        if (this.isSortServerSide) {
            this.sort.sortChange.subscribe(res => {
                this.sortChange.emit(res);
            });
        } else {
            this.dataSource.sort = this.sort;
        }
        if (this.isPaginationServerSide) {
            this.paginator.pageSizeOptions = this.pageSizeOptions;
            this.paginator.length = this.paginatorTotalRows;
            this.paginator.pageSize = this.pageSize;
            this.paginator.pageIndex = this.pageIndex;
        } else {
            if (this.paginator) {
                this.dataSource.paginator = this.paginator;
            }
        }
    }

    getColumnNameById(id: number): string {
        return this.dynamicColumns.find(el => el._id === id);
    }

    // Search filter
    applyFilter(event: string) {
        if (this.isSearchServerSide) {
            this.searchEvent.emit(event);
            return;
        }

        const filterValue = event;
        this.dataSource.filter = filterValue.trim().toLowerCase();
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    cdkCopyToClipboardCopied(e: ClipboardEvent) {
        // show message
    }

    ngOnDestroy(): void {
        if (this.bpSub) this.bpSub.unsubscribe();
    }

    onSortChange(sort: MatSort) {
        if (sort.direction === '') {
            this.sort.sort({ id: this.activeSortColumn, start: this.matSortDirection, disableClear: false });
        }
        this.refSortChange.emit(sort);
    }
    handleSortChange(sort: MatSort) {
        this.sortData(sort); // Call the first method
        // Call the second method
        this.onSortChange(sort);
    }
}
