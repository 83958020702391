import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HttpResponse,
    HttpErrorResponse,
    HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../authentication';
import { environment } from '../../../../environments/environment';
import { ToastMessageService } from 'src/app/shared/services/toast-message.service';
import { SpinnerService } from '../..';
import { STORAGE_KEY } from '../../constants/enum.constants';
import { StorageService } from 'src/app/shared/services/storage.service';
import { isPlatformBrowser } from '@angular/common';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { AuthModuleService } from 'src/app/new-auth/auth-module.service';
// import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    isMobile: any;
    bypassURL = [
        `${environment.IXFI_API}auth/pre-signup-verify`,
        `${environment.IXFI_API}convert`,
        `${environment.IXFI_API}auth/refresh-token`,
        `${environment.IXFI_API}auth/reset-2fa`,
        `${environment.IXFI_API}v1/referral/collect-commission`
    ];
    private router = inject(Router);
    private translate = inject(TranslateService);
    private auth = inject(AuthenticationService);
    private toast = inject(ToastMessageService);
    private loader = inject(SpinnerService);
    private storage = inject(StorageService);
    private errorHandlerService = inject(ErrorHandlerService);
    private platformId = inject(PLATFORM_ID);
    private authModuleService = inject(AuthModuleService);
    // private cookieService = inject(CookieService);

    constructor() {
        this.isMobile = JSON.stringify(this.storage.getItem(STORAGE_KEY.IS_MOBILE));
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    if (this.checkErrorCondition(event)) {
                        // handle loader if it doesn't stop on error
                        if (isPlatformBrowser(this.platformId)) {
                            setTimeout(() => this.checkOverLay(), 15000);
                            this.checkForError(event);
                        }
                    }
                }
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 503) {
                    this.auth.clearData();
                    this.router.navigate(['/server-management']);
                } else if (!this.isMobile) {
                    this.toast.showErrorToast({
                        toastMessage: this.translate.instant('TOASTER_MESSAGE.SOMETHING_WENT_WRONG_MESSAGE')
                    });
                }
                this.checkOverLay();
                return throwError(error);
            })
        );
    }

    private checkErrorCondition(event: any) {
        return (
            event.body?.status &&
            event.body?.message &&
            ![200, 600, 416, 204].includes(event.body.status) &&
            !this.bypassURL.includes(event.url)
        );
    }

    private checkForError(event) {
        let errorCode = {
            419: () => this.auth.clearAuthData(false, true),
            503: () => {
                this.auth.clearData();
                this.storage.setItem('Retry-After', JSON.stringify(event.body['Retry-After']));
                this.router.navigate(['/server-management']);
            },
            403: () => {
                this.toast.showErrorToast({ toastMessage: event.body.message });
                this.auth.clearDataAfterInvalidCaptcha();
            }, // after invalid captcha clear auth data and redirect to login
            451: () => {
                // this.byPassChecksForFarming();
                // after invalid access on compliance user should redirect to popup
                if (!JSON.parse(localStorage.getItem(STORAGE_KEY.IS_MOBILE) || 'false'))
                    this.router.navigate(['home/welcome']);
            },
            444: () => {
                // this.byPassChecksForFarming();
                this.router.navigate(['auth/ixfi-pin']);
            },
            409: () => {
                const code = event?.body?.data?.error_code;
                this.router.navigateByUrl(`restricted?q=${code}`);
            },
            429: () => {
                const code = event?.body?.data?.error_code;
                if (code) this.router.navigateByUrl(`restricted?q=${code}`);
                else this.toast.showErrorToast({ toastMessage: event.body.message });
            },
            423: () => {
                // this.byPassChecksForFarming();
                const code = event?.body?.data?.reason;
                if (code === 'kyc_incomplete') this.authModuleService.openWalletKYC();
            },
            default: () => {
                if ([401, 417].includes(event.body.status)) this.auth.clearData();
                if (
                    event.body.status === 418 &&
                    event.body.data.hasOwnProperty('is_invalid_otp') &&
                    event.body.data.is_invalid_otp
                ) {
                    this.errorHandlerService.setInvalidOtpData(event.body.data.invalid_otp_method);
                }
                if (
                    !(
                        (event.body.status === 500 && event.body.data?.is_withdraw_value_higher) ||
                        (event.body.status === 418 && event.body.data.hasOwnProperty('isError'))
                    )
                )
                    this.toast.showErrorToast({ toastMessage: event.body.message });
            }
        };
        if (errorCode[event.body.status]) errorCode[event.body.status]();
        else errorCode['default']();
    }

    private checkOverLay() {
        if (this.loader.overlayRef) this.loader.hide();
    }

    // private byPassChecksForFarming() {
    //     const returnUrl = this.cookieService.get(STORAGE_KEY.RETURN_URL) || STORAGE_KEY.EMPTY_STRING;
    //     if (returnUrl.includes(environment.IXFI_FARMING)) {
    //         this.authModuleService.redirectToSubDomain(returnUrl);
    //     }
    // }
}

export const httpErrorInterceptor = [{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }];
